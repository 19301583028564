import { createContext,useCallback,useContext,useEffect, useMemo, useState } from "react";
import ServicesContext from "./contextServices";
import * as Helpers from '../helpers/helpers'
const ProfileContext = createContext()


const ProfileProvider =  ({children}) =>{
    const services = useContext(ServicesContext)
    const [user,setUser]=useState({})
    const [update,setUpdate] = useState(false)
    let data = {}
    let rutes = Helpers.concatRutes().rutesHref
  

    const getUserInfo = async () =>{
        const email = Helpers.decryptData(window.localStorage.getItem("token-t")) || false;
        if(!email &&  window.location.pathname !== '/login'){
           // window.location.href = '/login';
        }
        if(email){
            setUser(Helpers.decryptData(window.localStorage.getItem("token-t")))
            
            // if (!result.data && 
            //     (result.response.status === 4021 || result.response.status === 401) && 
            //     window.location.pathname !== '/login') {
            //     window.location.href = '/login';
            //     localStorage.removeItem('token-t');
            //     localStorage.removeItem('user_details');
            // }
            setUpdate(true)
            
        }

        
    }

    function permisionForRutes() {
        if(Object.keys(user).length>0 ){
            if( user.pages.findIndex( i => i.name === window.location.pathname).length===0){
                window.location.href = '/';
            }
        }
    }

    useEffect(()=>{
        console.log("ESTOY CARGANDO!!!!");
        
        getUserInfo()
    },[])

  
   

    return  <ProfileContext.Provider value={{user,setUser,getUserInfo,permisionForRutes}}>
                {children}
            </ProfileContext.Provider>;
    
}
export { ProfileProvider}
export default ProfileContext