import React from 'react'

export default function CardChart({ name="Sin datos",update="sin datos", data =0,Icon=null,color="gray"}) {
  return (
    <div className='w-full bg-white rounded-lg shadow-lg dark:bg-slate-800'>
        <div className='flex flex-row items-center justify-between gap-3 p-3 pr-2 dark:text-white' id='up'>
            <div id='info-card' className='flex flex-col '>
                <label  className='text-sm'>{name}</label>
                <label style={{color:color}}  className='text-5xl '>{data}</label>
            </div>
            <div id='img-card ' style={{backgroundColor:color}}  className={'flex justify-center w-14 h-14 p-2  rounded-full align-center '}>
            {Icon?(<Icon className="text-white"  width={40}/>):":("}
            </div>
        </div>
        <hr />
        <div id='down' className='flex gap-1 p-1 text-sm text-gray-300'>
           <span style={{color:'green', fontSize:30, marginTop:-2}}>• </span> {update}
        </div>
    </div>
  )
}
