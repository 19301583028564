import React, { useEffect, useState, useContext,useCallback,useReducer } from 'react'
import * as Helpers from '../../helpers/helpers'
import AlertContext from '../../context/contextAlert'
import { useNavigate , useLocation, useLoaderData } from "react-router-dom"
import ServicesContext from '../../context/contextServices'
import {  PlusIcon,DocumentDuplicateIcon } from '@heroicons/react/24/solid'

import lan from '../../translate'
import { country } from '../../helpers/country'
import ProfileContext from '../../context/contextProfile'
import moment from 'moment'
import FileDocument from '../../components/files/fileDocument'
import DinamicField from '../../components/customForm/dinamicFields'


const selectedValueInfo = {
    value:"",
    label:""
}




export default function FileCreate() {

    const services =  useContext(ServicesContext)
    const MySwal = useContext(AlertContext)
    const {user}  =  useContext(ProfileContext)
    const location = useLocation();
    const navigate = useNavigate();
    const [body,setBody] = useState([])
    const [customFields,setCustomFields] = useState([])
    const [idUser, setIdUser] = useState(0)
    const [isUpdate, setIsUpdate] = useState(false)
    const [required,setRequired] = useState([])
    const [options, setOptions] = useState(selectedValueInfo)
    

      let [fileList, dispatchFile] = useReducer((fileList, { type, value, position }) => {
        switch (type) {
          case "add":
            return [...fileList, value];
          case "show":
            return value
          case "update":
              fileList[position] =  value
            return [...fileList];
          case "remove":
            return fileList.filter((data,i)=> data.name !== value)
          case "load":
            return value
          default:
            return fileList;
        }
      }, []);


    const setBodyData = useCallback(event => {
        const { name, value } = event.target;
        setBody({ ...body, [name]: value });
        if(!value){
            setRequired({ ...required, [name]: lan.user_alert_fielderror })
        }else{
            setRequired({ ...required, [name]: "" })
        }
        
        body[name] = value
        required[name] = value

    });

    const setBodyDataSelect = useCallback(event => {
        const { name, value } = event.target;
        setBody({ ...body, [name]: value.label });
        if(!value){
            setRequired({ ...required, [name]: lan.user_alert_fielderror })
        }else{
            setRequired({ ...required, [name]: "" })
        }
    });

    const setCheckActive = useCallback(event => {
        const { name, checked } = event.target;
        setBody({ ...body, [name]: checked });
      
    });


    

  

    function loadData(state) {
        if(state){
         setRequired({
             no:'',
             name:'',
         })
         setBody(state)
         setIdUser(state._id)
         setIsUpdate(true)

        }
        
    }

    function loadFields() {
        
        const result = services.File.customFields()
        
         result.map((b,i)=>{
            body[b.name] = b.value
            required[b.name] = b.required
        })
        setBody({
            ...body,
            dateCreate:moment().format('DD/MM/YYYY'),
            hourCreate:moment().hours() + ":"+ moment().minutes(),
            user_id:user._id
        })
        setCustomFields(result)
    }

    useEffect(()=>{
        loadData(location.state)
        if(body.length===0){
            loadFields()
        }
    },[body])


    async function save() {
        if(Helpers.checkIsRequired(required) || fileList.length===0){

            MySwal.fire({
                confirmButtonColor: '#1EA0D5',
                icon: "warning",
                text:lan.user_alert_field_empty
              })
              return
        }

        MySwal.fire({
            title: lan.loading_save,
            allowOutsideClick: false,
          })
        MySwal.showLoading();

        let file = fileList[0]
        file.description = body.description
        file.category = body.category
        

        if(!Helpers.checkIsRequired(required) && fileList.length>0){
 
            const result = await services.File.uplodFile(file)
            if(result.status ===201){
                MySwal.fire({
                    confirmButtonColor: '#1EA0D5',
                    icon: "success",
                    text:lan.alert_create_generic
                  })
                return navigate('/files')
            }

            if(result.response && result.response.data.statusCode === 400){
                return  MySwal.fire({
                    confirmButtonColor: '#1EA0D5',
                    icon: "error",
                    title:lan.alert_errorcreate_generic,
                    text: "Error: "+result.response.data.message
                    })
            }

            MySwal.fire({
                confirmButtonColor: '#1EA0D5',
                icon: "error",
                text:lan.user_alert_error500
                })
                
        }
    }

    async function update() {
        delete body.create
        delete body.__v
        delete body._id
        delete body.dateCreate
        delete body.hourCreate
        delete body.userInfo
        
        if(Helpers.checkIsRequired(required)){

            MySwal.fire({
                confirmButtonColor: '#1EA0D5',
                icon: "warning",
                text:lan.user_alert_field_empty
            })
        }
        if(!Helpers.checkIsRequired(required) ){
            const result = await services.File.update(body,idUser)
            if(result.status ===200){
                MySwal.fire({
                    confirmButtonColor: '#1EA0D5',
                    icon: "success",
                    text:lan.alert_update_generic
                  })
                  navigate("/files")
                return 
            }

            if(result.response && result.response.data.statusCode === 400){
                return  MySwal.fire({
                    confirmButtonColor: '#1EA0D5',
                    icon: "error",
                    title:lan.alert_errorupdate_generic,
                    text: "Error: "+result.response.data.message
                    })
            }

            MySwal.fire({
                confirmButtonColor: '#1EA0D5',
                icon: "error",
                text:lan.user_alert_error500
                })
        }
        
        
    }

    async function getFileByPublicId(public_id) {
        const result = await services.File.getFile({public_id:public_id,type:body.type})
        if(result.data&&result.data.secure_url){
           window.open(result.data.secure_url,'_blank').focus()
        }else{
            MySwal.fire({
                confirmButtonColor: '#1EA0D5',
                icon: "error",
                text:"No lo encontramos :("
                })
        }
    }

    function remove(id) {
        MySwal.fire({
            title: lan.flight_alert_delete,
            text: lan.user_alert_deleteuser_sub,
            icon: 'warning',
            confirmButtonColor: "#db292f",
            confirmButtonText: "Si",
            showCancelButton: true,
            cancelButtonText: 'No'
         }).then(async function(btn){
            // function when confirm button clicked
            if(btn.isConfirmed){
                const result = await services.File.removeFile(id)
                if(result.data&&result.data.result ==='ok'){
                    MySwal.fire({
                        confirmButtonColor: '#1EA0D5',
                        icon: "success",
                        text:lan.flight_alert_deletesuccess
                      })
                      navigate("/files")
                }
    
                if(!result.data|| result.data.result==='not found'){
                    MySwal.fire({
                        confirmButtonColor: '#1EA0D5',
                        icon: "error",
                        text:lan.flight_alert_deleteerror
                      })
                }
            }
    
            
            
         });     
    }

    return (
        <div className="space-y-10 divide-y divide-gray-900/10">
        <div className="grid grid-cols-1 pt-10 gap-x-8 gap-y-8 md:grid-cols-3">
            
          <div className="px-4 sm:px-0">
            <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">{lan.file_title_create}</h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">{lan.file_title_create_sub}</p>
          </div>
    
          <div className="bg-white shadow-sm dark:ring-1 dark:ring-inset dark:ring-white/10 dark:bg-slate-800 ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
            <div className="px-4 py-6 sm:p-8">
            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                {customFields.length>0&&customFields.map((fields,i)=>
                    <DinamicField 
                    type={fields.type}
                    value={body[fields.name]}
                    apiType={fields.apiType}
                    name={fields.name}
                    required={required[fields.name]}
                    label={fields.label}
                    setBodyData={setBodyData}
                    />
                )}

                
            </div>
            </div>
           
          </div>
        </div>


        <div className="grid grid-cols-1 pt-10 gap-x-8 gap-y-8 md:grid-cols-3">
            
            <div className="px-4 sm:px-0">
              <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">{lan.file_title_file}</h2>
              <p className="mt-1 text-sm leading-6 text-gray-600">{lan.file_title_file_sub}</p>
            </div>
      
            <div className="bg-white shadow-sm dark:ring-1 dark:ring-inset dark:ring-white/10 dark:bg-slate-800 ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
              <div className="px-4 py-6 sm:p-8">
            {!isUpdate&&
                  
                  
                
              <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                {fileList.length>0&&fileList.map((data,i)=>
                    <div className="flex flex-wrap sm:col-span-3">
                        <FileDocument ruteFile={Helpers.getRootFolder('template/')} category={body.category}  position={i} fileList={fileList} setMethod={dispatchFile}/>
                    </div>
                )}

                <div className="sm:col-span-3">
                    {fileList.length<1&&
                    <div onClick={()=>dispatchFile({ type: "add", value: "", position:0})}  className='flex flex-wrap w-full gap-12 cursor-pointer'>
                        <div className="flex justify-center px-6 py-8 mt-2 border border-indigo-600 border-dashed rounded-lg">
                            <div className="text-center">
                                <PlusIcon  className="h-8 mx-auto text-gray-300 w-15" aria-hidden="true"/>
                                <div className="flex justify-center mt-4 text-sm leading-6 text-center text-gray-600">
                                
                                </div>
                                <p className="text-xs leading-5 text-gray-600"> Agregar  Archivos </p>

                            </div>
                        </div>
                    </div>
                    }
                </div>
              </div>
            }

            {isUpdate&&
            <div className="sm:col-span-3">
                <div onClick={()=>getFileByPublicId(body.public_id)}  className='flex flex-wrap w-full gap-12 cursor-pointer'>
                    <div className="flex justify-center px-6 py-8 mt-2 border border-indigo-600 border-dashed rounded-lg">
                        <div className="text-center">
                            <DocumentDuplicateIcon  className="h-12 mx-auto text-gray-300 w-25" aria-hidden="true"/>
                            <div className="flex justify-center mt-4 text-sm leading-6 text-center text-gray-600">
                            
                            </div>
                            <p className="text-xs leading-5 text-gray-600"> {body.name} </p>

                        </div>
                    </div>
                </div>
            
            </div>
            }
              </div>
             
            </div>
          </div>
    
        
    
       
    
        <div className="flex items-center justify-end px-4 py-4 border-t col-span-full gap-x-6 border-gray-900/10 dark:border-white/10 sm:px-8">
              <button type="button" className="text-sm font-semibold leading-6 text-blue-primary" onClick={()=>navigate(-1)}>
                {lan.user_button_cancel}
              </button>
              <button
                type="submit"
                onClick={()=> isUpdate? remove(body._id) : save()}
                className={!isUpdate? "px-3 py-2 text-sm font-semibold text-white rounded-md shadow-sm  hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-blue-primary  focus-visible:outline-indigo-600 " :
                "px-3 py-2 text-sm font-semibold text-white rounded-md shadow-sm  hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-red-800  focus-visible:outline-indigo-600 "}
              >
                {isUpdate? "Eliminar" :lan.user_button_save}
              </button>
            </div>
      </div>
      )

}