import { createContext, useState } from "react";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Toaster  } from 'sonner'

const AlertContext = createContext()

const AlertProvider =  ({children}) =>{
    const MySwal = withReactContent(Swal)
    
    return <AlertContext.Provider value={MySwal}>
                <Toaster position="top-right" expand={true} richColors closeButton />
                {children}
            </AlertContext.Provider>;
    
}
export { AlertProvider}
export default AlertContext