import React, {useEffect} from 'react'
import lan from '../../translate'
import { ArrowLongRightIcon, GlobeAsiaAustraliaIcon, GlobeAmericasIcon } from '@heroicons/react/20/solid'
import moment from 'moment'

export default function EticketModal({data,required,setBodyData,setCheckActive}) {
    console.log('data', data)
    function itsEntry(port) {
        const embarkationPorts = [
            "SDQ",
            "PUJ",
            "STI",
            "POP",
            "JBQ",
            "LRM",
            "BRX",
            "AZS",
            "SAI",
            "XXX"
          ];
          
        return embarkationPorts.includes(port);
    }
    
    // useEffect(() => {

    // },data.exceedsMoneyLimit)
  return (
    <div>
        <div className="w-full">
          
                <h1 className='text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl'>Verifica que tus datos esten Correctos:</h1>
           <br/>
        </div>
        
        <div className='grid gap-x-6 gap-y-8 sm:grid-cols-12'>
            <div className='flex flex-col justify-between sm:col-span-12'>
                <div className='flex flex-row flex-wrap justify-between'>
                    <div className='grid flex-col justify-center justify-items-center'>
                        <label className='text-3xl font-medium text-green-700'>{data.airport_origin}</label>
                        <label className='text-sm text-blue-primary'>{moment(data.departureInfo.scheduledTime.local).format("HH:MM")}</label>
                        <label className='text-xs'>{data.departureInfo.airport.name}</label>
                        <label className='text-xs'>{data.departureInfo.airport.municipalityName}</label>
                    </div>
                    <div className='grid flex-col justify-center justify-items-center'>
                    <label className='text-4xl font-medium'>{data.airline}</label>
                    <label className='text-xl '>{data.no_flight}</label>
                    </div>
                    <div className='grid flex-col justify-center justify-items-center'>
                    <label className='text-3xl font-medium text-red-700'>{data.airport_destination}</label>
                        <label className='text-sm text-blue-primary'>{moment(data.arrivalInfo.scheduledTime.local).format("HH:MM")}</label>
                        <label className='text-xs'>{data.arrivalInfo.airport.name}</label>
                        <label className='text-xs'>{data.arrivalInfo.airport.municipalityName}</label>
                    </div>
                </div>
                <div className='flex flex-row flex-wrap justify-between justify-items-center sm:col-span-12 '>
                <ArrowLongRightIcon color='green' width={50} height={30}/>
                <GlobeAsiaAustraliaIcon color='green' width={50} height={30}/>
                <ArrowLongRightIcon color='green' width={50} height={30}/>
                <GlobeAmericasIcon color='green' width={50} height={30}/>
                <ArrowLongRightIcon color='green' width={50} height={30}/>
                </div>
            </div>
            <div className="sm:col-span-3">
                <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                    {lan.eticket_field_passport_number} <span className={required.passport_number?"text-red-700":""}>*</span>
                    </label>
                    <div className="mt-2">
                    <input
                        type="text"
                        name={"passport_number"}
                        id={"passport_number"}
                        value={data.passport_number}
                        onChange={setBodyData}
                        autoComplete="given-name"
                        className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                    />
                    </div>
                    <span className='text-xs text-red-700'>{required.passport_number?required.passport_number:""}</span>
            </div>
            <div className="sm:col-span-3">
                <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                    {lan.eticket_field_given_names} <span className={required.given_names?"text-red-700":""}>*</span>
                    </label>
                    <div className="mt-2">
                    <input
                        type="text"
                        name={"given_names"}
                        id={"given_names"}
                        value={data.given_names}
                        onChange={setBodyData}
                        autoComplete="given-name"
                        className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                    />
                    </div>
                    <span className='text-xs text-red-700'>{required.given_names?required.given_names:""}</span>
            </div>
            <div className="sm:col-span-3">
                <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                    {lan.eticket_field_surname} <span className={required.surname?"text-red-700":""}>*</span>
                    </label>
                    <div className="mt-2">
                    <input
                        type="text"
                        name={"surname"}
                        id={"surname"}
                        value={data.surname}
                        onChange={setBodyData}
                        autoComplete="given-name"
                        className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                    />
                    </div>
                    <span className='text-xs text-red-700'>{required.surname?required.surname:""}</span>
            </div>
            <div className="sm:col-span-3">
                <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                    {lan.eticket_field_nationality} <span className={required.nationality?"text-red-700":""}>*</span>
                    </label>
                    <div className="mt-2">
                    <input
                        type="text"
                        name={"nationality"}
                        id={"nationality"}
                        value={data.nationality}
                        onChange={setBodyData}
                        autoComplete="given-name"
                        className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                    />
                    </div>
                    <span className='text-xs text-red-700'>{required.nationality?required.nationality:""}</span>
            </div>
            <div className="sm:col-span-3">
                <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                    {lan.eticket_field_date_of_birth} <span className={required.airdate_of_birthline?"text-red-700":""}>*</span>
                    </label>
                    <div className="mt-2">
                    <input
                        type="text"
                        name={"date_of_birth"}
                        id={"date_of_birth"}
                        value={data.date_of_birth}
                        onChange={setBodyData}
                        autoComplete="given-name"
                        className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                    />
                    </div>
                    <span className='text-xs text-red-700'>{required.date_of_birth?required.date_of_birth:""}</span>
            </div>
            <div className="sm:col-span-3">
                <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                    {lan.eticket_field_sex} <span className={required.gender?"text-red-700":""}>*</span>
                    </label>
                    <div className="mt-2">
                    <select
                        name={"gender"}
                        id={"gender"}
                        value={data.gender}
                        onChange={setBodyData}
                        autoComplete="given-name"
                        className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                    >
                    <option className='dark:bg-slate-900' value={""}>Seleccione</option> 
                    <option className='dark:bg-slate-900' value={"M"}>M</option>   
                    <option className='dark:bg-slate-900' value={"F"}>F</option>    
                    </select>
                    </div>
                    <span className='text-xs text-red-700'>{required.gender?required.gender:""}</span>
            </div>

            <div className="sm:col-span-3">
                <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                    {lan.eticket_field_country_code} <span className={required.country_code?"text-red-700":""}>*</span>
                    </label>
                    <div className="mt-2">
                    <input
                        type="text"
                        name={"country_code"}
                        id={"country_code"}
                        value={data.country_code}
                        onChange={setBodyData}
                        autoComplete="given-name"
                        className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                    />
                    </div>
                    <span className='text-xs text-red-700'>{required.country_code?required.country_code:""}</span>
            </div>

            <div className="sm:col-span-3">
                <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                    {lan.eticket_field_place_of_birth} <span className={required.place_of_birth?"text-red-700":""}>*</span>
                    </label>
                    <div className="mt-2">
                    <input
                        type="text"
                        name={"place_of_birth"}
                        id={"place_of_birth"}
                        value={data.place_of_birth}
                        onChange={setBodyData}
                        autoComplete="given-name"
                        className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                    />
                    </div>
                    <span className='text-xs text-red-700'>{required.place_of_birth?required.place_of_birth:""}</span>
            </div>

            <div className="sm:col-span-3">
                <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                    {lan.eticket_field_country} <span className={required.country?"text-red-700":""}>*</span>
                    </label>
                    <div className="mt-2">
                    <input
                        type="text"
                        name={"country"}
                        id={"country"}
                        value={data.country}
                        onChange={setBodyData}
                        autoComplete="given-name"
                        className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                    />
                    </div>
                    <span className='text-xs text-red-700'>{required.country?required.country:""}</span>
            </div>

            <div className="sm:col-span-3">
                <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                    {lan.eticket_field_cityName} <span className={required.cityName?"text-red-700":""}>*</span>
                    </label>
                    <div className="mt-2">
                    <input
                        type="text"
                        name={"cityName"}
                        id={"cityName"}
                        value={data.cityName}
                        onChange={setBodyData}
                        autoComplete="given-name"
                        className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                    />
                    </div>
                    <span className='text-xs text-red-700'>{required.cityName?required.cityName:""}</span>
            </div>
            <div className="sm:col-span-3">
                <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                    {lan.eticket_field_address} <span className={required.permanentAddress?"text-red-700":""}>*</span>
                    </label>
                    <div className="mt-2">
                    <input
                        type="text"
                        name={"permanentAddress"}
                        id={"permanentAddress"}
                        value={data.permanentAddress}
                        onChange={setBodyData}
                        autoComplete="given-name"
                        className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                    />
                    </div>
                    <span className='text-xs text-red-700'>{required.permanentAddress?required.permanentAddress:""}</span>
            </div>
            <div className="sm:col-span-3">
                <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                    Email (Opcional)  <span className={required.email_number?"text-red-700":""}>*</span>
                    </label>
                    <div className="mt-2">
                    <input
                        type="email"
                        name={"email_number"}
                        id={"email_number"}
                        value={data.email_number}
                        onChange={setBodyData}
                        autoComplete="given-name"
                        className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                    />
                    </div>
                    <span className='text-xs text-red-700'>{required.email_number?required.email_number:""}</span>
            </div>
            
        </div>
        <br/>
        {itsEntry(data.airport_destination)&&(
            <div className='flex w-full '>
            <h1><b>INFORMACIÓN ADUANAL</b></h1>
            </div>
        )}
        
        <br/>
        {itsEntry(data.airport_destination)&&(
            <div className='flex flex-col gap-3 p-3 border-2 border-gray-600 rounded-md'>
        <div className="relative flex gap-x-3">
            <div className="flex flex-col text-sm leading-6">
                <label htmlFor="exceedsMoneyLimit" className="text-xs font-medium text-gray-900 dark:text-white">
                ¿TRAE (N) O LLEVA (N) CONSIGO O EN SU (S) EQUIPAJE (S), USTED Y/O SUS FAMILIARES, VALORES EN MONEDA U OTRO INSTRUMENTO DE PAGO, UN MONTO SUPERIOR A US$10,000.00 O SU EQUIVALENTE EN OTRO (S) TIPO (S) DE MONEDA (S)?  &nbsp; &nbsp;
                <input
                    id="exceedsMoneyLimit"
                    name="exceedsMoneyLimit"
                    type="checkbox"
                    onClick={setCheckActive}
                    className="w-4 h-4 border-gray-300 rounded text-blue-primary focus:blue-primary"
                />
                </label>
                
                {data.exceedsMoneyLimit&&(
                    <div className='flex gap-2 pt-2'>
                    
                    <label htmlFor="ammount" className="flex flex-col text-xs font-medium text-gray-900 dark:text-white">
                    MONTO(USD): * &nbsp; &nbsp;
                    <input
                        id="ammount"
                        name="ammount"
                        type="number"
                        onChange={setBodyData}
                        className="block w-72 rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                        />
                    </label>
                    <br/>
                    <label htmlFor="declaredOriginValue" className="flex flex-col text-xs font-medium text-gray-900 dark:text-white">
                    DECLARE EL ORIGEN DE LOS VALORES: * &nbsp; &nbsp;
                    <input
                        id="declaredOriginValue"
                        name="declaredOriginValue"
                        type="text"
                        onChange={setBodyData}
                        className="block w-72 rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                        />
                    </label>
                </div>
                )}
            </div>     
        </div>
        <div className="relative flex gap-x-3">
            <div className="text-sm leading-6">
            <label htmlFor="hasAnimalsOrFood" className="text-xs font-medium text-gray-900 dark:text-white">
            ¿TRAE CONSIGO O EN SU EQUIPAJE ANIMALES VIVOS, PLANTAS O PRODUCTOS ALIMENTICIOS?      &nbsp; &nbsp;        
            <input
                id="hasAnimalsOrFood"
                name="hasAnimalsOrFood"
                type="checkbox"
                onClick={setCheckActive}
                className="w-4 h-4 border-gray-300 rounded text-blue-primary focus:blue-primary"
            />
            </label>
            </div>        
        </div>

        
        </div>
        )}
        
    </div>
  )
}
