import {useEffect,useContext, useState} from 'react'
import ProfileContext from '../../context/contextProfile'
import AlertContext from '../../context/contextAlert'
import * as Helpers from '../../helpers/helpers'
import ServicesContext from "../../context/contextServices";
export default function Configuration({children}) {
    const {permisionForRutes,user}  =  useContext(ProfileContext)
    const [config,setConfig] = useState(false)
    const services = useContext(ServicesContext)
    let load = Helpers.decryptData(localStorage.getItem("token-t")).dataLoad

    async function getInfo() {
        console.log("CARGANDO CONFIGURACION");
        
        try {
            const user = await services.Users.getUserByToken()
            const agency = await services.Agencies.getAgencyInfo(user.data.tenantId)
            console.log('agency :>> ', agency);
            const loadLang = await services.File.getFileOneLang(agency.data.language)
            let  data = Helpers.decryptData(localStorage.getItem("token-t"))
           
            window.localStorage.setItem("token-t",Helpers.encryptData({
                ...data,
                ...user.data,
                agency:{...agency.data},
                dataLoad:true
            }))
            window.localStorage.setItem("lan-t",JSON.stringify(loadLang.data))
           window.location.reload()
        } catch (error) {
            console.log('error', error)
           // window.location.href = "/login"
        }
        
    }
    function sleep(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    
    useEffect( ()=>{
        Helpers.changeTheme()
        if(!load){
            getInfo()
        }
       
       console.log("SOY EL MIDDLAWARE");
       
    },[])


    // if(Object.keys(user).length===0){
        
    //     return <h1>cargando...</h1>
    // }
    return load? children : <h1>cargando...</h1>;
}
