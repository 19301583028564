



import {useEffect,useContext} from 'react'
import ProfileContext from '../../context/contextProfile'
export default function Permission({children}) {
    const {permisionForRutes,user}  =  useContext(ProfileContext)

    


    if(Object.keys(user).length===0){
        
        return <h1>cargando...</h1>
    }
    return children;
}

