import lan from '../../translate';
import api from '../api/create';
import * as Helpers from "../../helpers/helpers"

function getAllUsers(limit=10,page=1) {
    return api.get(`/users?limit=${limit}&skip=${page}`)
}

function login(body) {
    return api.post(`/auth/login`,body)
}

function getUserByToken() {
    return api.get(`/users/infouser`)
}

function getUsersByEmail(email) {
    return api.get(`/users/email/${email}`)
}

function findAllLike(body,limit=1000,page=1) {
    return api.post(`/users/find?limit=${limit}&skip=${page}`,body)
}

function findRangeDate(body,limit=1000,page=1) {
    return api.post(`/users/find/rangedate?limit=${limit}&skip=${page}`,body)
}

function saveUser(body) {
    return api.post(`/users/`,body)
}

function updateUser(body,id) {
    return api.patch(`/users/${id}`,body)
}

function updateUserRolesAndPages(body,id) {
    return api.patch(`/users/roles/${id}`,body)
}

function deleteUser(id) {
    return api.delete(`/users/${id}`)
}

function updateUserPassword(body) {
    return api.patch(`/users/changepassword`,body)
}

function logOut() {
    let infoUser = Helpers.decryptData(localStorage.getItem("token-t"))
    infoUser.accessToken = ""
    window.localStorage.setItem("token-t",Helpers.encryptData(infoUser))
    window.location.href = "/login"
}

function Headers() {
    const headers = {
        fullName:lan.headers_user_fullname,
        email:lan.headers_user_email,
        roles:lan.headers_user_roles,
        dateCreate:lan.headers_user_dataCreate,
        isActive:lan.headers_user_isActive

    }

    return headers
}

function fields(setState) {
    const fields_init = {
        fullName:{name:"Nombres", key:"fullName", required: true,value:""},
        email:{name:"Correo", key:"email", required: true,value:""},
        roles:{name:"Roles", key:"roles", required: true,value:""},
        pages:{name:"Paginas", key:"pages", required: true,value:""},
        isActive:{name:"Activo", key:"isActive", required: true,value:""},

    }
    setState(fields_init)
}

function btnTable() {
    const btn = {
        create:{name:lan.table_btn_create, colorClass:"block px-3 py-2 text-sm font-semibold text-center text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" },
        update:{name:lan.table_btn_edit, colorClass:"text-blue-primary   hover:text-indigo-900"},
        delete:{name:lan.table_btn_delete, colorClass:"text-red-800   hover:text-red-900"},
    }
    return btn
}



export {
    getAllUsers,
    login,
    getUsersByEmail,
    logOut,
    Headers,
    btnTable,
    fields,
    saveUser,
    deleteUser,
    updateUser,
    updateUserPassword,
    findAllLike,
    findRangeDate,
    updateUserRolesAndPages,
    getUserByToken
}

