import React, {lazy} from 'react';
const languaje = localStorage.getItem("lan-t")
if(languaje ==="undefined" || !languaje){
    let lan = (await import("./trans.es")).default;
    console.log('lan', lan)
    localStorage.setItem("lan-t",JSON.stringify(lan))
    window.location.href = "/login"
    
}
const languaJson = JSON.parse(localStorage.getItem("lan-t"))






export default  languaJson