
module.exports ={
    api:          process.env.REACT_APP_URL,
    SECRECT:      process.env.REACT_APP_SECRET,
    SOCKET_URL:       process.env.REACT_APP_SOCKET,
    LOGO:"https://res.cloudinary.com/trolbis-dev/image/upload/v1730221091/aventryx/images/aventr_xghsce.png",
    LOGO_WHITE:"https://res.cloudinary.com/trolbis-dev/image/upload/v1730221091/aventryx/images/aventr_xghsce.png",
    FAVICON:"https://res.cloudinary.com/trolbis-dev/image/upload/v1730221074/aventryx/images/favicon_brdeye.png"
}


