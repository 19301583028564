import React, { useEffect,useReducer, useState, useContext,useCallback } from 'react'
import * as Helpers from '../../helpers/helpers'
import AlertContext from '../../context/contextAlert'
import { PlusIcon } from '@heroicons/react/24/solid'

import { useNavigate , useLocation, useLoaderData } from "react-router-dom"
import FileDocument from '../../components/files/fileDocument'

import ServicesContext from '../../context/contextServices'
import lan from '../../translate'
import { country } from '../../helpers/country'
import ProfileContext from '../../context/contextProfile'
import moment from 'moment'
import Select from '../../components/select/select'
import DinamicField from '../../components/customForm/dinamicFields'
import Combobox from '../../components/combobox/combobox'
import FileOneDocument from '../../components/files/fileOneDocument'
import { usePrompt } from '../../components/hooks/usePrompt'

export default function Settings() {

    const [body,setBody] = useState([])
    const [agencyId,setAgencyID] = useState("")
    const [customFields,setCustomFields] = useState([])
    const [required,setRequired] = useState([])
    const services =  useContext(ServicesContext)
    const MySwal = useContext(AlertContext)
    const {user}  =  useContext(ProfileContext)
    const navigate = useNavigate();

    let [imageList, dispatchImage] = useReducer((imageList, { type, value, position }) => {
        switch (type) {
            case "add":
            return [...imageList, value];
            case "show":
            return value
            case "update":
                imageList[position] =  value
            return [...imageList];
            case "remove":
            return imageList.filter((data,i)=> data.name !== value)
            case "load":
            return value
            default:
            return imageList;
        }
        }, []);
    let [favList, dispatchFav] = useReducer((imageList, { type, value, position }) => {
        switch (type) {
            case "add":
            return [...imageList, value];
            case "show":
            return value
            case "update":
                imageList[position] =  value
            return [...imageList];
            case "remove":
            return imageList.filter((data,i)=> data.name !== value)
            case "load":
            return value
            default:
            return imageList;
        }
        }, []);
    let [fileList, dispatchFile] = useReducer((fileList, { type, value, position }) => {
        switch (type) {
            case "add":
            return [...fileList, value];
            case "show":
            return value
            case "update":
                fileList[position] =  value
            return [...fileList];
            case "remove":
            return fileList.filter((data,i)=> data.name !== value)
            case "load":
            return value
            default:
            return fileList;
        }
        }, []);



    const setBodyData = useCallback(event => {
        console.log('event :>> ', event);
        let { name, value,type,checked } = event.target;

        if(type==="checkbox" || type==="switch"){
            setBody({ ...body, [name]: checked });
            setRequired({ ...required, [name]: "" })
            body[name] = checked
            required[name] = checked
        }else{
            value = type ==="number"? parseFloat(value):value
            setBody({ ...body, [name]: value });
            if(!value){
                setRequired({ ...required, [name]: lan.user_alert_fielderror })
            }else{
                setRequired({ ...required, [name]: "" })
            }
            body[name] = value
            required[name] = value
        }
        
        console.log('required', required)
        

    });
    
    function loadFields() {
        
        const result = services.Agencies.customFields()
        console.log('result*(&&*&*^^&*&^*&^*', result)
         result.map((b,i)=>{
            body[b.name] = b.value
            required[b.name] = b.required
        })
        setBody({
            ...body,
            dateCreate:moment().format('DD/MM/YYYY'),
            hourCreate:moment().hours() + ":"+ moment().minutes(),
            user_id:user._id
        })
        
        setCustomFields(result)
    }

    useEffect(()=>{
        
        
   
        
        if(body.length===0){
            let data = Helpers.decryptData(localStorage.getItem("token-t")).agency
           
            loadFields()
            dispatchFile({ type: "show", value: data.images_company, position:0})
            dispatchImage({ type: "show", value: data.logo, position:0})
            dispatchFav({ type: "show", value: data.favicon, position:0})
         
            setBody({...data})
            setAgencyID(data._id)
            //loadData(location.state)
            console.log('data', data,body._id,data._id)
        }
    },[body])


    useEffect(()=>{
        console.log("fileList updated:", fileList);
    },[fileList])

   async  function save() {
    await fileList
       console.log('fileList :>> ',await fileList);
        MySwal.fire({
            title: lan.loading_save,
            allowOutsideClick: false,
          })
          MySwal.showLoading();
        delete body.create
        delete body.__v
        delete body._id
        delete body.dateCreate
        delete body.hourCreate
        delete body.userInfo
        delete body.dateStart
        delete body.has_transport
        delete body.has_breaksfast
        delete body.subscription
        console.log('required', required)
        if(Helpers.checkIsRequired(required)){

            MySwal.fire({
                confirmButtonColor: '#1EA0D5',
                icon: "warning",
                text:lan.user_alert_field_empty
            })
        }
        if(!Helpers.checkIsRequired(required) ){
            const diffFile =  fileList.filter(
                fileBody => !body.images_company.some(newFile => fileBody.name === newFile.name)
                )
                console.log('diffFile :>> ', diffFile);
                if(diffFile.length>0){
                  const resultFile = await services.File.uplodMultipleFile({files:diffFile})
                  if(resultFile.status !== 200 && resultFile.status !==201){
                    return  MySwal.fire({
                      confirmButtonColor: '#1EA0D5',
                      icon: "error",
                      title:lan.alert_errorcreate_generic,
                      text: "Error: FileServer"
                      }) 
                  }
                  for (const key in resultFile.data) {
                    body.images_company.push(resultFile.data[key])
                  }
                 
                }else{
                    console.log("ENTRO AQUI POR QUE NO TIENE FILE");
                    body.images_company = fileList
                    
                }

                const diffFileImage =  imageList.filter(
                    fileBody => !body.logo.some(newFile => fileBody.name === newFile.name)
                    )
                    console.log('diffFileImage', diffFileImage)
                    if(diffFileImage.length>0){
                      const resultFileImage = await services.File.uplodMultipleFile({files:diffFileImage})
                      if(resultFileImage.status !== 200 && resultFileImage.status !==201){
                        return  MySwal.fire({
                          confirmButtonColor: '#1EA0D5',
                          icon: "error",
                          title:lan.alert_errorcreate_generic,
                          text: "Error: FileServer"
                          }) 
                      }
                      for (const key in resultFileImage.data) {
                        body.logo.push(resultFileImage.data[key])
                      }
                     
                    }else{
                        body.logo = imageList
                    }

                    const diffFileFav =  favList.filter(
                        fileBody => !body.favicon.some(newFile => fileBody.name === newFile.name)
                        )
                        if(diffFileFav.length>0){
                          const resultFileFav = await services.File.uplodMultipleFile({files:diffFileFav})
                          if(resultFileFav.status !== 200 && resultFileFav.status !==201){
                            return  MySwal.fire({
                              confirmButtonColor: '#1EA0D5',
                              icon: "error",
                              title:lan.alert_errorcreate_generic,
                              text: "Error: FileServer"
                              }) 
                          }
                          for (const key in resultFileFav.data) {
                            body.favicon.push(resultFileFav.data[key])
                          }
                         
                        }else{
                            body.favicon = favList
                        }
          //  body.country = body.country?._id? body.country._id:body.country
           // body.images = fileList
            console.log('body.images', body,fileList)

            const result = await services.Agencies.updateAgency(agencyId,body)
            if(result.status ===200){
                MySwal.fire({
                    confirmButtonColor: '#1EA0D5',
                    icon: "success",
                    text:lan.alert_update_generic
                  })
                  
                  let  data = Helpers.decryptData(localStorage.getItem("token-t"))
                  window.localStorage.setItem("token-t",Helpers.encryptData({
                    ...data,
                    dataLoad:false
                }))
               // window.location.reload()
                return 
            }

            if(result.response && result.response.data.statusCode === 400){
                return  MySwal.fire({
                    confirmButtonColor: '#1EA0D5',
                    icon: "error",
                    title:lan.alert_errorupdate_generic,
                    text: "Error: "+result.response.data.message
                    })
            }

            MySwal.fire({
                confirmButtonColor: '#1EA0D5',
                icon: "error",
                text:lan.user_alert_error500
                })
        }
        
    }
  return (
    <div>
     <div className='flex justify-center p-1 font-bold text-white border-2 rounded bg-blue-secondary'>
            <h1>{lan.agency_name_title}</h1>
        </div><br/>
        <div className="grid max-w-full grid-cols-2 p-3 border border-slate-700 rounded-xl gap-x-8 gap-y-8 sm:grid-cols-12">
                {customFields.length>0&&customFields.map((fields,i)=>
                    <DinamicField 
                    type={fields.type}
                    value={body[fields.name]}
                    name={fields.name}
                    col={fields.col}
                    options={fields.options}
                    getValue={fields.getValue??fields.getValue}
                    required={required[fields.name]}
                    //onBlur={sumPriceSell}
                    labels={fields.labels??fields.labels}
                    services={fields.services??fields.services}
                    label={fields.label}
                    setBodyData={setBodyData}
                    />
                )}
        </div>




        <div className="grid max-w-full">
        <FileOneDocument
            dispatchFile={ dispatchImage }
            fileList={imageList}
            updateMethod={save}
            ruteFile={Helpers.getAgencyFolder(`logo/`)}
            category={"portrait"}
            accept={".jpg,.jpeg,.png"}
            title={lan.agency_logo_title}
            subtitle={lan.agency_logo_subtittle}
        />
        </div>

        <div className="grid max-w-full">
        <FileOneDocument
            dispatchFile={ dispatchFav }
            fileList={favList}
            updateMethod={save}
            ruteFile={Helpers.getAgencyFolder(`logo/favicon/`)}
            category={"portrait"}
            accept={".png,.ico"}
            title={lan.agency_favicon_tittle}
            subtitle={lan.agency_favicon_subtitle}
        />
        </div>

        <div className="grid max-w-full">
        <FileOneDocument
            dispatchFile={ dispatchFile }
            fileList={fileList}
            updateMethod={()=>save()}
            ruteFile={Helpers.getAgencyFolder(`photos/`)}
            category={"portrait"}
            accept={".jpg,.jpeg,.png"}
            limit={20}
            title={lan.agency_photo_title}
            subtitle={lan.agency_photo_sub}
        />
        </div>
                <br/>
        <div className="flex items-center justify-end px-4 py-4 border-t col-span-full gap-x-6 border-gray-900/10 dark:border-white/10 sm:px-8">
              
              <button
                type="submit"
                onClick={()=> save()}
                className="px-3 py-2 text-sm font-semibold text-white rounded-md shadow-sm bg-blue-primary hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {lan.user_button_save}
              </button>
            </div>
    </div>
  )
}
