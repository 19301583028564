import moment from "moment";
import { othersNavigation,navigation,subNavigations,userNavigation } from "../routers";
import CryptoJS from "crypto-js";
import config from '../config'
import { country } from "./country";
import axios from "axios";





function getNestedProperty(obj, propertyPath=[]) {
  let result = ""
  if(propertyPath.length===0){
    return "sin label"
  }

  
  result = propertyPath.map((property,i)=>{
    if (!property.includes('.')) {
      return obj[property];
    }else{
      return property.split('.').reduce((accumulator, key) => {
        return accumulator && accumulator[key] !== undefined ? accumulator[key] : undefined;
      }, obj);
    }
  })
  
  return  result.join("    - ")
}

function formatToCurrency(number,headers) {
  const amount = parseFloat(number)
  if(!isNaN(amount) && headers.typeField && headers.typeField ==="currency" ){
    return `$${amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
  }
  return number
}

function clearCheckboxes(idDiv) {
  // Selecciona todos los checkboxes dentro del div con id 'myDiv'
  const checkboxes = document.querySelectorAll(`#${idDiv} input[type="checkbox"]`);

  // Itera sobre los checkboxes y desmárcalos
  checkboxes.forEach(function(checkbox) {
    checkbox.checked = false;
  });
}

function formatDataDocuments(data) {
  return {
  "passengers":data.passengers,
  "ident":data.ident,
  "dateStart":data.dateStart,
  "dateReturn":data.dateReturn,
  "countryOrigin":country.filter((ct,i)=> ct.code ===  data.ruteFlight.countryOrigin)[0].name,
  "countryOriginAlpha3":country.filter((ct,i)=> ct.code ===  data.ruteFlight.countryOrigin)[0]["alpha-3"],
  "countryDestination":country.filter((ct,i)=> ct.code ===  data.ruteFlight.countryDestination )[0].name,
  "countryCodeOrigin":data.ruteFlight.countryOrigin,
  "countryCodeDestination":data.ruteFlight.countryDestination,
  "code": data.code,
  "flightOrigin":data.ruteFlight.iataOrigin,
  "flightDestination":data.iataDestination,
  "no_flight":data.ruteFlight.no_flight,
  "hour_start":data.ruteFlight.hour_start,
  "airline":data.airlinesNames,
  "hotelTemplate":"false",
  "seguroTemplate":"false",
  "tourTemplate":"false",
  "flightReturnTemplate":"false",
  "eticket":"false",
  "work_id":data._id
  }
}


function encryptData(data) {
  if(!data) return ""
  return CryptoJS.AES.encrypt(
    JSON.stringify(data),
    config.SECRECT
  ).toString();
}

function decryptData(data) {
  try {
  if(!data) return ""
  const bytes = CryptoJS.AES.decrypt(data, config.SECRECT);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (error) {
  }
}


function logo(url_default) {
  try {
    let agency = decryptData(localStorage.getItem("token-t"))?.agency
    console.log('agency :>> ', agency);
    if(agency && agency.logo.length>0){
        return agency.logo[0].file
    }
  } catch (error) {
    localStorage.removeItem("token-t")
  }
  return url_default
}

function favIcon(url_default) {
  try {
    let agency = decryptData(localStorage.getItem("token-t"))?.agency
  if(agency && agency.favicon.length>0){
      return agency.favicon[0].file
  }
  } catch (error) {
    localStorage.removeItem("token-t")
  }
  return url_default
  
}


function changeTheme() {
  let agency = decryptData(localStorage.getItem("token-t"))?.agency
  let primary_color =  agency?agency.color_primary: "#5e17eb"
  let secondary_color =  agency?agency.color_second: "#8c03e2"
  let title = agency?agency.companyName:"Aventryx"
  let favicon = favIcon(config.FAVICON)
  console.log('primary_color', agency,primary_color)
  document.documentElement.style.setProperty("--color-primary", primary_color); // Cambia el color primario
  document.documentElement.style.setProperty("--color-secondary", secondary_color); // Cambia el color secundario
  document.title = title
  document.getElementById("favicon").href = `${favicon}?t=${new Date().getTime()}`
}


function getAgencyFolder(rute) {
  let agencyName = decryptData(localStorage.getItem("token-t"))?.agency.companyName
  let ruteNormal = rute.toLocaleLowerCase()
      .normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      .replace(/[^a-zA-Z0-9]/g, "").trim()
  return `aventryx/${agencyName}/${ruteNormal}`
}

function getRootFolder(rute) {
  let ruteNormal = rute.toLocaleLowerCase()
      .normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      .replace(/[^a-zA-Z0-9]/g, "").trim()
  return `aventryx/${ruteNormal}`
}


async function uploadMultipleFile(fileList,ruteFile,services) {
  const user = decryptData(localStorage.getItem("token-t"))
  let fileResult = []
  fileList.forEach(async data  => {
    data.fileRef = ruteFile
    data.user_id = user._id
    const result = await services.uplodFile(data)
    fileResult.push(result)
  });
  return fileResult
}


function validateEmail(value) {

    let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (value.match(validRegex))  return true;

    return false;
}

function convertObjectToArray(obj){
    return Object.keys(obj).filter(function(x) { 
        return obj[x] !== false; 
    });
}

function removeRepeat(obj,objNew) {

  for (const i in obj) {
    if(Object.keys(obj[i])[0] === Object.keys(objNew)[0]){
      obj[i] = objNew
      return true

    }

  }
  return false
  
}            

function setChildrenRuteFormat(user,val,index,navigationChoose) {
  if( index !== -1){
    let newChildren = []
    if(val.children.length>0){
      
      val.children.map((child)=>{
        if(user.pages[index].children.indexOf(child.href) !== -1){
          newChildren.push(child)
        }
      })
      
    }
    let newItem = val
    return {...val, children: newChildren}
   }
}
function setAllNavigate(navigationChoose,navigationRutes,setNavigationRutes) {
  let arrayNavigation = []
  let user = decryptData(window.localStorage.getItem("token-t"))
  navigationChoose.map((val)=>{
    if(Object.keys(user).length >0){
     let index = user.pages.findIndex(i => i.name === val.href)
     arrayNavigation.push(setChildrenRuteFormat(user,val,index,navigationRutes.navigation))
    }
  })
  return arrayNavigation.filter((data,i)=> data !== undefined)
}
function permissionForRute(navigationRutes, setNavigationRutes) {

   
    let newArrayNavigation = setAllNavigate(navigation,navigationRutes, setNavigationRutes)
    let newArraySubNavigations = setAllNavigate(subNavigations,navigationRutes, setNavigationRutes)
    let newArrayOthersNavigation = setAllNavigate(othersNavigation,navigationRutes, setNavigationRutes)
    let newArrayUserNavigation = setAllNavigate(userNavigation,navigationRutes, setNavigationRutes)
    setNavigationRutes(prevNavigate => {
      return {
        ...prevNavigate, 
        navigation:newArrayNavigation,
        subNavigations:newArraySubNavigations,
        othersNavigation:newArrayOthersNavigation,
        userNavigation:newArrayUserNavigation
      }
    })
    
      
  }

  async function bodyEticket(body) {
    return {
      "permanentAddress": body.permanentAddress,
      "country": body.country,
      "cityName": body.cityName,
      "name": body.given_names,
      "lastname": body.surname,
      "birthday": body.date_of_birth,
      "birthday_place": body.place_of_birth,
      "birtday_place_code": body.country_code,
      "eticket_type":"SALIDA",
      "gender": body.gender,
      "passport": body.passport_number,
      "civil_state": body.civil_state,
      "ocupacion": body.ocupacion,
      "tel_number": "8097564123",
      "email_number": "info@trolbis.com",
      "airport_origin": body.airport_origin,
      "airport_destination": body.airport_destination,
      "airline": body.airline,
      "date_start":moment(body.date_start,"YYYY-MM-DD").format('DD/MM/YYYY'),
      "no_flight": body.no_flight,
      "motivate": body.motivate,
      "ammount":  body.ammount,
      "email_number":  body.email_number,
      "declaredOriginValue":  body.declaredOriginValue,
      "hasAnimalsOrFood":  body.hasAnimalsOrFood,
      "exceedsMoneyLimit":  body.exceedsMoneyLimit,
      "dateCreate":moment().format('DD/MM/YYYY'),
      "hourCreate":moment().hours() + ":"+ moment().minutes(),
      "ip":  (await axios.get("https://api.ipify.org/?format=json")).data.ip
    }
  }


  function concatRutes() {
      let allRutes = navigation.concat(subNavigations,userNavigation,othersNavigation)
      let pathnames = allRutes.map((data,i)=> data.href)
      return {rutes:navigation.concat(subNavigations,userNavigation,othersNavigation),rutesHref:pathnames}
  }

  function getYearsOld(date) {
    return moment().diff(date, 'years');
  }

  function permissionForAction(rolesUser=[],roles=[]){
      let result  = roles.map((rol)=>rolesUser.includes(rol)).filter(Boolean)
      return result.length>0;
  }


  function changeFormatData(value,headersKey,headers) {
      if(value && typeof value === 'object'){
        let keyObject = headers[headersKey].keyObject
        return value[keyObject]
      }
      if(value){
        return value.toString()
      }
    return ""
  }

  function allLetter(value)
  {
   var letters = /^[A-Za-z\s]+$/;
   if(value.match(letters))
     {
      return true;
     }
   else
     {
     return false;
     }
  }

  function changeDataColor(value,colorChoose = false) {
    if(colorChoose){
      let classColor = colorChoose.filter(
        (data,i)=> {
          if(data ){
            return data.split(':')[0].toLowerCase() === value.toLowerCase()
          }
        })[0]?.split(":")[1].toLowerCase()
      return `py-4  text-sm font-medium  ${classColor}  whitespace-nowrap sm:pl-0` 
    }
    return `py-4  text-sm font-medium text-grey-900 dark:text-white  whitespace-nowrap sm:pl-0`
  }


  function checkIsRequired(required){
    let fields = Object.values(required)
    for (const i in fields) {
        if (fields[i]!=='') return true
    }
    return false
  } 

  function dateIsBefore(dateOne,dateTwo) {
    const date_one = moment(dateOne, 'YYYY-MM-DD'); 
    const date_two = moment(dateTwo, 'YYYY-MM-DD'); 
    return date_two.isBefore(date_one)
  }




export {
    validateEmail,
    convertObjectToArray,
    permissionForRute,
    concatRutes,
    permissionForAction,
    changeFormatData,
    checkIsRequired,
    getYearsOld,
    removeRepeat,
    encryptData,
    decryptData,
    uploadMultipleFile,
    formatDataDocuments,
    changeDataColor,
    allLetter,
    bodyEticket,
    getNestedProperty,
    formatToCurrency,
    dateIsBefore,
    clearCheckboxes,
    logo,
    changeTheme,
    getAgencyFolder,
    getRootFolder
}