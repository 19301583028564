import React, { useEffect,useReducer, useState, useContext,useCallback } from 'react'
import * as Helpers from '../../helpers/helpers'
import AlertContext from '../../context/contextAlert'
import { PlusIcon } from '@heroicons/react/24/solid'

import { useNavigate , useLocation, useLoaderData } from "react-router-dom"
import FileDocument from '../../components/files/fileDocument'

import ServicesContext from '../../context/contextServices'
import lan from '../../translate'
import { country } from '../../helpers/country'
import ProfileContext from '../../context/contextProfile'
import moment from 'moment'
import Select from '../../components/select/select'
import DinamicField from '../../components/customForm/dinamicFields'
import Combobox from '../../components/combobox/combobox'

const selectedValueInfo = {
    value:"",
    label:""
}




export default function ToursPackageCreate() {

    const services =  useContext(ServicesContext)
    const MySwal = useContext(AlertContext)
    const {user}  =  useContext(ProfileContext)
    const location = useLocation();
    const navigate = useNavigate();
    const [body,setBody] = useState([])
    
    const [customFields,setCustomFields] = useState([])
    const [idUser, setIdUser] = useState(0)
    const [isUpdate, setIsUpdate] = useState(false)
    const [required,setRequired] = useState([])
    const [options, setOptions] = useState(selectedValueInfo)
    let [tourList, dispatch] = useReducer((tourList, { type, value, position }) => {
        switch (type) {
          case "add":
            return [...tourList, value];
          case "update":
            tourList[position] =  value
            return [...tourList];
          case "remove":
            return tourList.filter((_, index) => index !== value);
          case "load":
            return value
          default:
            return tourList;
        }
      }, []);
    
    let [fileList, dispatchFile] = useReducer((fileList, { type, value, position }) => {
    switch (type) {
        case "add":
        return [...fileList, value];
        case "show":
        return value
        case "update":
            fileList[position] =  value
        return [...fileList];
        case "remove":
        return fileList.filter((data,i)=> data.name !== value)
        case "load":
        return value
        default:
        return fileList;
    }
    }, []);


    const setBodyData = useCallback(event => {
        let { name, value,type,checked } = event.target;

        if(type==="checkbox" || type === "switch"){
            setBody({ ...body, [name]: checked });
            setRequired({ ...required, [name]: "" })
            body[name] = checked
            required[name] = checked
        }else{
            value = type ==="number"? parseFloat(value):value
            setBody({ ...body, [name]: value });
            if(!value){
                setRequired({ ...required, [name]: lan.user_alert_fielderror })
            }else{
                setRequired({ ...required, [name]: "" })
            }
            body[name] = value
            required[name] = value
        }
        
        
        

    });

    const setBodyDataSelect = useCallback(event => {
        const { name, value } = event.target;
        dispatch({ type: "update", value: value, position:name})
    });

    const setCheckActive = useCallback(event => {
        const { name, checked } = event.target;
        setBody({ ...body, [name]: checked });
      
    });


    

  

    function loadData(state) {
        console.log('state', state)

        if(state){
         setRequired({
             no:'',
             name:'',
         })
         dispatch({ type: "load", value: state.tours, position:0})
         dispatchFile({ type: "show", value: state.images, position:0})
         setIdUser(state._id)
         console.log('body', body)
         setBody({...state,date_start:moment(state.date_start,"DD/MM/YYYY").format('YYYY-MM-DD')})
         setIsUpdate(true)
        }
        
    }

    function loadFields() {
        
        const result = services.Tour.customFiledsPackage()
        console.log('result', result)
         result.map((b,i)=>{
            body[b.name] = b.value
            required[b.name] = b.required
        })
        setBody({
            ...body,
            dateCreate:moment().format('DD/MM/YYYY'),
            hourCreate:moment().hours() + ":"+ moment().minutes(),
            user_id:user._id
        })
        setRequired(...required,{country:true})
        setCustomFields(result)
    }

    useEffect(()=>{
        if(!body.date_start){
            loadData(location.state)
        }
       
        
        
        if(body.length===0){
            loadFields()
        }
    },[body.date_start])


    async function save() {
        MySwal.fire({
            title: lan.loading_save,
            allowOutsideClick: false,
          })
          MySwal.showLoading();
        if(Helpers.checkIsRequired(required) ||  tourList.length ===0){

            MySwal.fire({
                confirmButtonColor: '#1EA0D5',
                icon: "warning",
                text:lan.user_alert_field_empty
              })
        }

       

        if(!Helpers.checkIsRequired(required) && tourList.length>0){
            if(fileList.length>0){
                console.log('fileList save', fileList)
                const resultFile = await services.File.uplodMultipleFile({files:fileList})
                if(resultFile.status !== 200 && resultFile.status !==201){
                  return  MySwal.fire({
                    confirmButtonColor: '#1EA0D5',
                    icon: "error",
                    title:lan.alert_errorcreate_generic,
                    text: "Error: FileServer"
                    }) 
                }
                body.images = resultFile.data
            }
            console.log('body.images', body.images)
            body.tours = tourList;
            body.date_start = moment(body.date_start,'YYYY-MM-DD').format('DD/MM/YYYY')
            console.log('body', body)
            const result = await services.Tour.createFullPackage(body)
            if(result.status ===201){
                MySwal.fire({
                    confirmButtonColor: '#1EA0D5',
                    icon: "success",
                    text:lan.alert_create_generic
                  })
                return navigate('/worktours/package')
            }

            if(result.response && result.response.data.statusCode === 400){
                return  MySwal.fire({
                    confirmButtonColor: '#1EA0D5',
                    icon: "error",
                    title:lan.alert_errorcreate_generic,
                    text: "Error: "+result.response.data.message
                    })
            }

            MySwal.fire({
                confirmButtonColor: '#1EA0D5',
                icon: "error",
                text:lan.user_alert_error500
                })
                
        }
    }

    async function update() {
        MySwal.fire({
            title: lan.loading_save,
            allowOutsideClick: false,
          })
          MySwal.showLoading();
        delete body.create
        delete body.__v
        delete body._id
        delete body.dateCreate
        delete body.hourCreate
        delete body.userInfo
        delete body.dateStart
        delete body.tourNames
        if(Helpers.checkIsRequired(required) ||  tourList.length ===0){

            MySwal.fire({
                confirmButtonColor: '#1EA0D5',
                icon: "warning",
                text:lan.user_alert_field_empty
            })
        }
        if(!Helpers.checkIsRequired(required) && tourList.length>0 ){
            const diffFile =  fileList.filter(
                fileBody => !body.images.some(newFile => fileBody.name === newFile.name)
                )
                
                if(diffFile.length>0){
                  const resultFile = await services.File.uplodMultipleFile({files:diffFile})
                  if(resultFile.status !== 200 && resultFile.status !==201){
                    return  MySwal.fire({
                      confirmButtonColor: '#1EA0D5',
                      icon: "error",
                      title:lan.alert_errorcreate_generic,
                      text: "Error: FileServer"
                      }) 
                  }
                  for (const key in resultFile.data) {
                    body.images.push(resultFile.data[key])
                  }
                 
                }else{
                    body.images = fileList
                }
            body.tours = tourList
            body.country = body.country?._id? body.country._id:body.country
           // body.images = fileList
            console.log('body.images', body.images)
            body.date_start = moment(body.date_start,"YYYY-MM-DD").format("DD/MM/YYYY")
            const result = await services.Tour.updatePackage(body,idUser)
            if(result.status ===200){
                MySwal.fire({
                    confirmButtonColor: '#1EA0D5',
                    icon: "success",
                    text:lan.alert_update_generic
                  })
                  navigate("/worktours/package")
                return 
            }

            if(result.response && result.response.data.statusCode === 400){
                return  MySwal.fire({
                    confirmButtonColor: '#1EA0D5',
                    icon: "error",
                    title:lan.alert_errorupdate_generic,
                    text: "Error: "+result.response.data.message
                    })
            }

            MySwal.fire({
                confirmButtonColor: '#1EA0D5',
                icon: "error",
                text:lan.user_alert_error500
                })
        }
        
        
    }

    useEffect(()=>{
    },[tourList])

  
    

    return (
        <div className="space-y-10 divide-y divide-gray-900/10">
        <div className="grid grid-cols-1 pt-10 gap-x-8 gap-y-8 md:grid-cols-3">
            
          <div className="px-4 sm:px-0">
            <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">{lan.tour_title}</h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">{lan.tour_titlesub}</p>
          </div>
    
          <div className="bg-white shadow-sm dark:ring-1 dark:ring-inset dark:ring-white/10 dark:bg-slate-800 ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
            <div className="px-4 py-6 sm:p-8">
            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                {customFields.length>0&&customFields.map((fields,i)=>
                    <DinamicField 
                    type={fields.type}
                    value={body[fields.name]}
                    name={fields.name}
                    options={fields.options}
                    required={required[fields.name]}
                    label={fields.label}
                    setBodyData={setBodyData}
                    />
                )}
            </div>
                <br/>
                <div className="sm:col-span-3">
                <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
                {lan.tour_field_country} <span className={required.country?"text-red-700":""}>*</span>
                </label>
                <Combobox
                nameDefault={"country"}
                labels={["name","code"]}
                services={services.Countries.findAllLike} 
                value={body.country?.name}
                method={setBodyData}/>

                <span className='text-xs text-red-700'>{required.country?required.country:""}</span>
                </div>

            </div>
            
           
          </div>
        </div>


        <div className="grid grid-cols-1 pt-10 gap-x-8 gap-y-8 md:grid-cols-3">
            
          <div className="px-4 sm:px-0">
            <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">{lan.tour_names_include}</h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">{lan.generic_list_subtitle}</p>
          </div>
    
          <div className="bg-white shadow-sm dark:ring-1 dark:ring-inset dark:ring-white/10 dark:bg-slate-800 ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
            <div className="px-4 py-6 sm:p-8">
                
            {tourList.length>0&& tourList.map((data,i) =>
            
                     <div className="grid max-w-3xl grid-cols-1 p-1 gap-x-8 gap-y-8 sm:grid-cols-1">
                    
                    <Combobox 
                    nameDefault={i}
                    labels={["name","country.name"]}
                    services={services.Tour.findAllLike} 
                    value={body.tours&&body.tours.length>0&&body.tours[i].name}
                    method={setBodyDataSelect}/>
                    <div>
                        <button type="button" onClick={()=>dispatch({ type: "remove", value: i, position:i})} className="px-3 py-2 text-sm font-semibold text-white bg-red-700 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" >
                            {lan.generic_list_btn_delete}
                        </button>
                    </div>
                
                
                </div>  
            )}
            <br/>
            <button type="button" onClick={()=>dispatch({ type: "add", value: "", position:0})} className="px-3 py-2 text-sm font-semibold text-white bg-green-700 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" >
                {lan.generic_list_btn_add}
            </button>
            </div>

        
           
          </div>
        </div>


        <div  className="grid grid-cols-1 pt-10 gap-x-8 gap-y-8 md:grid-cols-3">
        
        <div className="px-4 sm:px-0">
          <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">{lan.tour_field_images}</h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">{lan.tour_imges_file}</p>
        </div>
  
        <div className="bg-white shadow-sm dark:ring-1 dark:ring-inset dark:ring-white/10 dark:bg-slate-800 ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
          <div className="px-4 py-6 sm:p-8">
            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

        
            {fileList.length>0&&fileList.map((data,i)=>
              <div className="flex flex-wrap sm:col-span-3">
                <FileDocument ruteFile={ Helpers.getAgencyFolder('tours/package/')} category={"images"}  position={i} fileList={fileList} setMethod={dispatchFile}/>
              </div>
            )}
             <div className="sm:col-span-3">
              <div onClick={()=>dispatchFile({ type: "add", value: "", position:0})} className='flex flex-wrap w-full gap-12 cursor-pointer'>
                <div className="flex justify-center px-6 py-8 mt-2 border border-indigo-600 border-dashed rounded-lg">
                  <div className="text-center">
                    <PlusIcon  className="h-8 mx-auto text-gray-300 w-15" aria-hidden="true"/>
                    <div className="flex justify-center mt-4 text-sm leading-6 text-center text-gray-600">
                     
                    </div>
                    <p className="text-xs leading-5 text-gray-600"> Agregar  Archivos </p>

                  </div>
              </div>
              </div>
                </div>
              
            </div>
          </div>
         
        </div>
    </div>
        
    
        
    
       
    
        <div className="flex items-center justify-end px-4 py-4 border-t col-span-full gap-x-6 border-gray-900/10 dark:border-white/10 sm:px-8">
              <button type="button" className="text-sm font-semibold leading-6 text-blue-primary" onClick={()=>navigate(-1)}>
                {lan.user_button_cancel}
              </button>
              <button
                type="submit"
                onClick={()=> isUpdate? update() : save()}
                className="px-3 py-2 text-sm font-semibold text-white rounded-md shadow-sm bg-blue-primary hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {lan.user_button_save}
              </button>
            </div>
      </div>
      )

}