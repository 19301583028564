import axios from 'axios'

import config from '../../config';
import * as Helpers from "../../helpers/helpers"
/**
 * Create an Axios Client with defaults
 */
const api = axios.create({
    baseURL: config.api,
    crossDomain: true,
    withCredentials: false,
    headers: {
        'Accept': 'application/json',
        'Content-Type':'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods':'GET, POST, PUT, DELETE',
        'Authorization': `Bearer ${Helpers.decryptData(localStorage.getItem("token-t"))?.accessToken}`,
        'X-TENANT-ID': `${Helpers.decryptData(localStorage.getItem("token-t"))?.tenantId}`,
    }
});


api.interceptors.response.use(response => {
    return response;
}, error => {
    if(error.response.status === 500){
    }
    if ((error.response.status === 4021 || error.response.status === 401) && window.location.pathname !== '/login') {
       window.location.href = '/login';
       localStorage.removeItem('token');
    }
    return error;
});
export default api;
