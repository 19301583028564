import React from 'react'
import lan from '../../translate/index'
export default function Paginations({data, changePage}) {
  console.log('data pagi :>> ', data);
  if(!data){
    return <></>
  }
  return (
    <nav
    className="flex items-center justify-between px-4 py-3 bg-white shadow-lg rounded-xl dark:bg-slate-800 sm:px-6"
    aria-label="Pagination"
  >
    <div className="hidden sm:block">
      <p className="text-sm text-gray-700 dark:text-white ">
        {lan.pagination_page}: <span className="font-medium">{data.pageCurrent}</span> {lan.pagination_result}: <span className="font-medium">{data.results?.length}</span> {lan.pagination_of}{' '}
        <span className="font-medium">{data.total}</span> {lan.pagination_total}
      </p>
    </div>
    <div className="flex justify-between flex-1 sm:justify-end">
      {data.pageCurrent !== 1 &&
        <a
        onClick={()=>changePage(data.pageCurrent-1)}
        className="relative inline-flex items-center px-3 py-2 text-sm font-semibold text-gray-900 rounded-md cursor-pointer dark:text-blue-primary ring-1 ring-inset hover:bg-gray-50 focus-visible:outline-offset-0"
        >
        {lan.pagination_backpage}
        </a>
      }


      {data.pageCurrent !== data.pagesTotal &&
        <a
        onClick={()=>changePage(data.pageCurrent+1)}
        className="relative inline-flex items-center px-3 py-2 ml-3 text-sm font-semibold text-gray-900 bg-white rounded-md cursor-pointer dark:text-white dark:bg-blue-primary ring-1 ring-inset hover:bg-gray-50 dark:hover:bg-blue-950 focus-visible:outline-offset-0"
        >
        {lan.pagination_nextpage}
        </a>
      }
    </div>
  </nav>
  )
}
