import FileDocument from './fileDocument'
import React, { useEffect, useState, useContext,useCallback,useReducer } from 'react'
import * as Helpers from '../../helpers/helpers'
import AlertContext from '../../context/contextAlert'
import { useNavigate , useLocation, useLoaderData } from "react-router-dom"
import ServicesContext from '../../context/contextServices'
import {  PlusIcon,DocumentDuplicateIcon } from '@heroicons/react/24/solid'

import lan from '../../translate'
export default function FileOneDocument({accept,dispatchFile,fileList=[],ruteFile,category,title,subtitle,limit = 1,updateMethod=null}) {

  return (
    <div className="grid grid-cols-1 pt-10 gap-x-8 gap-y-8 md:grid-cols-3">
            
            <div className="px-4 sm:px-0">
              <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">{title}</h2>
              <p className="mt-1 text-sm leading-6 text-gray-600">{subtitle}</p>
            </div>
      
            <div className="bg-white shadow-sm dark:ring-1 dark:ring-inset dark:ring-white/10 dark:bg-slate-800 ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
              <div className="px-4 py-6 sm:p-8">
            {true&&
                  
                  
                
              <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                {fileList.length>0&&fileList.map((data,i)=>
                    <div className="flex flex-wrap sm:col-span-3">
                        <FileDocument accept={accept} updateMethod={updateMethod} ruteFile={ruteFile} category={category}  position={i} fileList={fileList} setMethod={dispatchFile}/>
                    </div>
                )}

                <div className="sm:col-span-3">
                    {fileList.length<limit&&
                    <div onClick={()=>dispatchFile({ type: "add", value: "", position:0})}  className='flex flex-wrap w-full gap-12 cursor-pointer'>
                        <div className="flex justify-center px-6 py-8 mt-2 border border-indigo-600 border-dashed rounded-lg">
                            <div className="text-center">
                                <PlusIcon  className="h-8 mx-auto text-gray-300 w-15" aria-hidden="true"/>
                                <div className="flex justify-center mt-4 text-sm leading-6 text-center text-gray-600">
                                
                                </div>
                                <p className="text-xs leading-5 text-gray-600"> Agregar  Archivos </p>

                            </div>
                        </div>
                    </div>
                    }
                </div>
              </div>
            }

           
              </div>
             
            </div>
          </div>
    
        
  )
}
