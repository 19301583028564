import React, { useEffect, useState, useContext,useCallback } from 'react'
import * as Helpers from '../../helpers/helpers'
import AlertContext from '../../context/contextAlert'
import { useNavigate , useLocation, useLoaderData } from "react-router-dom"
import ServicesContext from '../../context/contextServices'
import lan from '../../translate'
import { country } from '../../helpers/country'
import ProfileContext from '../../context/contextProfile'
import moment from 'moment'
import Select from '../../components/select/select'
import Modal from '../../components/modal/modal'
import DinamicField from '../../components/customForm/dinamicFields'
import axios from "axios";
import EticketModal from './eticketModal'
import WebcamComponent from '../../components/webcam/webcam'
import { io } from 'socket.io-client';
import config from '../../config'
import Spinner from '../../components/loading/spinner'
import {ExclamationCircleIcon, CheckCircleIcon, TrashIcon } from '@heroicons/react/24/solid'
import { toast } from 'sonner'

const socket = io(config.SOCKET_URL)

const selectedValueInfo = {
    value:"",
    label:""
}

let initialState = {
    "airline": "",
    "airlineAndFlight": "",
    "no_flight": "",
    "airportOrigin": "",
    "airportDestination": "",
    "passport_number": "",
    "surname": "",
    "given_names": "",
    "nationality": "",
    "date_of_birth": "",
    "gender": "",
    "country_code": "",
    "place_of_birth": "",
    "date_of_issue": "",
    "date_of_expiry": "",
    "country": "",
    "cityName": "",
    email_number:"",
}

const bodyRequired = {
    "airline": true,
    "no_flight": true,
    "passport_number": true,
    "surname": true,
    "given_names": true,
    "nationality": true,
    "date_of_birth": true,
    "gender": true,
    "country_code": true,
    "place_of_birth": true,
    "date_of_issue": true,
    "date_of_expiry": true,
    "country": true,
    "cityName": true
}


export default function EticketPhoto() {

    const services =  useContext(ServicesContext)
    const MySwal = useContext(AlertContext)
    const {user}  =  useContext(ProfileContext)
    const location = useLocation();
    const navigate = useNavigate();
    const [body,setBody] = useState(initialState)
    const [customFields,setCustomFields] = useState([])
    const [idUser, setIdUser] = useState(0)
    const [isUpdate, setIsUpdate] = useState(false)
    const [urlEticket, setUrlEticket] = useState("")
    const [required,setRequired] = useState(bodyRequired)
    const [open,setOpen] = useState(false)
    const [etickets,setEtickets] = useState([])
    const [options, setOptions] = useState(selectedValueInfo)
    const [loading, setLoading] = useState(false)

    const setBodyData = useCallback(event => {
        const { name, value } = event.target;
        setBody({ ...body, [name]: value });
        if(!value){
            setRequired({ ...required, [name]: lan.user_alert_fielderror })
        }else{
            setRequired({ ...required, [name]: "" })
        }
        console.log('body', body)
        body[name] = value
        required[name] = value

    });


    function itsEntry(port) {
        const embarkationPorts = [
            "SDQ",
            "PUJ",
            "STI",
            "POP",
            "JBQ",
            "LRM",
            "BRX",
            "AZS",
            "SAI",
            "XXX"
          ];
          
        return embarkationPorts.includes(port);
    }

    const setBodyDataApi = useCallback(event => {

        setBody({ ...body, ...event });
        console.log('evente', event)
        
        Object.keys(event).map((data,i)=>{
            console.log('event[data]', event[data])
            if(event[data]){
                required[data] = ""
            }else{
                required[data] = lan.user_alert_fielderror
            }
        })
        
        
        
        console.log('required', required)

    });

    const setCheckActive = useCallback(event => {
        const { name, checked } = event.target;
        setBody({ ...body, [name]: checked });
      
    });

    const setBodyDataFile = useCallback(async event => {
        
        toast.loading("loading...")
        const file = event.target.files[0]
        // const maxAllowedSize = 2 * 1024 * 1024;
        // if(file.size> maxAllowedSize){
        //     return MySwal.fire({
        //         confirmButtonColor: '#1EA0D5',
        //         icon: "warning",
        //         text:"Archivo muy pesando, eliga uno que pese menos de 2mb"
        //       })
        // }
        const base64 = await convertBase64(file)
        setBody({ ...body, base64: base64.split(",")[1] });
        toast.dismiss()
        toast.success("Se agrego el archivo")
    });

    function convertBase64(file) {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file)
          fileReader.onload = () => {
            resolve(fileReader.result);
          }
          fileReader.onerror = (error) => {
            reject(error);
          }
        })
    }

  

  

    function loadData(state) {
        if(state){
         setRequired({
             no:'',
             name:'',
         })
         setBody(state)
         setIdUser(state._id)
         setIsUpdate(true)
        }
        
    }

    function loadFields() {
        
        const result = services.File.customEticketFields()
        console.log('result', result)
         result.map((b,i)=>{
            body[b.name] = b.value
            required[b.name] = b.required
        })
        setBody({
            ...body,
            base64:"",
            dateCreate:moment().format('DD/MM/YYYY'),
            hourCreate:moment().hours() + ":"+ moment().minutes(),
        })
        setCustomFields(result)
    }

    useEffect(()=>{
        loadData(location.state)
        loadFields()
        let etickets = JSON.parse(window.localStorage.getItem("etickets"))
        if (etickets) {
            setEtickets(etickets) 
        }
        
    },[])

    useEffect(()=>{

        socket.on('eticket'+body.passport_number, (message) => {
            console.log('message1 :>> ', message);
            let data = JSON.parse(window.localStorage.getItem("etickets"))
            console.log('data1 :>> ', data);
            for (const i in data) {
                console.log('data2 :>> ', data[i]);
                if(data[i].status === "process"){
                    data[i].status = message.ok? "success":"error"
                    data[i].msg = message.msg
                    data[i].url = message.msg
                    window.localStorage.setItem("etickets",JSON.stringify(data))
                    setEtickets(data)
                    message.ok?toast.success('Se termino el eticket'):toast.error('Error en el eticket')
                    return
                }
            }
            console.log('data :>> ', data);
        });
        return () => {
            socket.off('eticket'+body.passport_number); // Limpiar el evento al desmontar el componente
        };
    },[body,setBody])
    
    async function save() {
          console.log('body', body)
          required.declaredOriginValue = ""
          required.ammount = ""
          required.exceedsMoneyLimit = ""
          required.hasAnimalsOrFood = ""
        if(Helpers.checkIsRequired(required) || body.base64 === ""){
            setOpen(false)
           return  MySwal.fire({
                confirmButtonColor: '#1EA0D5',
                icon: "warning",
                text:lan.user_alert_field_empty
              })
        }

        if(!Helpers.checkIsRequired(required)){
            setOpen(false)
            const bodyResult = await Helpers.bodyEticket(body)
            const result = await services.File.eticketPhoto(bodyResult)
            console.log('result', result)
            etickets.push(
            {
                status:"process",
                msg: result.data.msg,
                name: body.surname + '-'+body.passport_number
            })
            window.localStorage.setItem("etickets", JSON.stringify(etickets ))
            setEtickets(JSON.parse( window.localStorage.getItem("etickets")))
            toast.success('Se agrego un eticket')
                
        }
    }

    async function validateData() {
        //return setOpen(true)
        
       
        MySwal.fire({
            title: "Validando datos...",
            allowOutsideClick: false,
          })
          MySwal.showLoading();
        if(body.base64 && body.no_flight && body.dateStart && body.motivate && body.ocupacion && body.civil_state  ){
            const result = await services.File.getInfoPassport({
                no_flight: body.no_flight,
                dateStart:body.dateStart,
                base64:body.base64
            })
            if(result.status === 201 && result.data.ok){
                console.log('first', result.data.msg)
                if(itsEntry(result.data.msg.airport_destination)){
                    setBodyDataApi({...result.data.msg,
                        ammount:"",
                        declaredOriginValue:"",
                        hasAnimalsOrFood:false,
                        exceedsMoneyLimit:false
                    })
                }else{
                    setBodyDataApi(result.data.msg)
                }
                
                MySwal.close();
                setOpen(true)
            }else{
                console.log('resu22lt', result)
                MySwal.fire({
                confirmButtonColor: '#1EA0D5',
                icon: "error",
                text:result.data?result.data.msg:result.response.data.message
                })
            }
            console.log('result', body)
        }else{
            MySwal.fire({
            confirmButtonColor: '#1EA0D5',
            icon: "warning",
            text:lan.user_alert_field_empty
            })
        }
    }


    function removeEticket(position) {
        let data = etickets
        delete data[position]
        let result = data.filter(item => item !== null);
        window.localStorage.setItem("etickets",JSON.stringify(result))
        setEtickets(result)
        toast.warning('Se Elimino un registro')

    }

    

    return (
        <div className="space-y-10 divide-y divide-gray-900/10">
        <div className="grid grid-cols-1 pt-10 gap-x-8 gap-y-8 md:grid-cols-3">
            
          <div className="px-4 sm:px-0">
            <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">{lan.eticket_title}</h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">{lan.eticket_subtitle}</p>
          </div>
    
          <div className="bg-white shadow-sm dark:ring-1 dark:ring-inset dark:ring-white/10 dark:bg-slate-800 ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
            <div className="px-4 py-6 sm:p-8">
            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                {customFields.length>0&&customFields.map((fields,i)=>
                    <DinamicField 
                    type={fields.type}
                    value={body[fields.name]}
                    apiType={fields.apiType}
                    name={fields.name}
                    required={required[fields.name]}
                    label={fields.label}
                    options={fields.options}
                    setBodyData={setBodyData}
                    />
                )}
                <div className="sm:col-span-6">
                    <div className="mt-2">
                        <input
                        type={"file"}
                        name={"base64"}
                        id={"base64"}
                        accept=".jpg,.jpeg,.png"
                        onChange={setBodyDataFile}
                        autoComplete="given-name"
                        className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                        />
                    </div>
                    {/* <div className="sm:col-span-6">
                        <WebcamComponent setUrl={setUrl}/>
                    </div> */}
                </div>
            </div>
            </div>
           
          </div>
        </div>
        <div className='flex flex-wrap justify-end gap-3 '>
        {etickets.length>0&&etickets.map((data,i)=>
        <div className='bg-white dark:ring-1 dark:ring-inset dark:ring-white/10 dark:bg-slate-800 ring-1 ring-gray-900/5 sm:rounded-lg md:col-span-2' >
            <div className='flex justify-end p-1 ' style={{display: data.status==="process"?"none":"flex"}}>
                <TrashIcon className='w-4 h-4 pointer-trash' onClick={()=>removeEticket(i)} color='red'/> 
            </div>
        <div className='p-3' >
        {data.status === "process"&&
            <div className='box-card-eticket'>
                <Spinner text='Espere el eticket...'/>
            </div>
        }
        {data.status === "error"&& 
            <div className='flex flex-col items-center box-card-eticket'>
                <ExclamationCircleIcon className='w-12 h-12' color='orange' /><br/>
                <button
                className="px-3 py-2 text-sm font-semibold text-white bg-orange-500 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {data.name}
              </button>
            </div>
        }
        {data.status === "success"&& 
            <div className='flex flex-col items-center box-card-eticket'>
                <CheckCircleIcon color='green' className='w-24 h-24 po'/><br/>
                <button
                onClick={()=> window.open(data.url,"_blank")}
                className="px-3 py-2 text-sm font-semibold text-white bg-green-700 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {data.name}
              </button>
            </div>
        }
        
        </div>
        </div>
        )}
        </div>
    
        {/* {urlEticket!==""&&(
            <div className='flex justify-between'>
                    <div className='flex'>
                        
                    </div>  
                    <div className='p-3 bg-white shadow-sm dark:ring-1 dark:ring-inset dark:ring-white/10 dark:bg-slate-800 ring-1 ring-gray-900/5 sm:rounded-lg md:col-span-2'>
                    {urlEticket===false&&(<label>Error al crear el eticekt, intentelo otra vez</label>)}
                    {urlEticket&& urlEticket!=="cargando"(
                        <div onClick={()=>window.open(urlEticket,"_blank")}  className='flex flex-wrap w-full gap-12 cursor-pointer'>
                        <div className="flex justify-center px-6 py-8 mt-2 border border-indigo-600 border-dashed rounded-lg">
                            <div className="text-center">
                                <DocumentDuplicateIcon  className="h-12 mx-auto text-gray-300 w-25" aria-hidden="true"/>
                                <div className="flex justify-center mt-4 text-sm leading-6 text-center text-gray-600">
                                
                                </div>
                                <p className="text-xs leading-5 text-gray-600"> Eticket</p>

                            </div>
                        </div>
                    </div>

                    )}
                    {loading&& <Spinner text='Espere mientras se hace el eticket...'/>}
                    
                    </div>
            </div>
        )} */}
    
       
    
        <div className="flex items-center justify-end px-4 py-4 border-t col-span-full gap-x-6 border-gray-900/10 dark:border-white/10 sm:px-8">
              <button type="button" className="text-sm font-semibold leading-6 text-blue-primary" onClick={()=>navigate(-1)}>
                {lan.user_button_cancel}
              </button>
              <button
                type="submit"
                hidden={!urlEticket}
                onClick={()=> window.open(urlEticket,"_blank")}
                className="px-3 py-2 text-sm font-semibold text-white bg-green-700 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Ver Eticket
              </button>

              <button
                type="submit"
                onClick={()=> validateData()}
                className="px-3 py-2 text-sm font-semibold text-white rounded-md shadow-sm bg-blue-primary hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {lan.user_button_save}
              </button>
            </div>
            <Modal 
            open={open} 
            setOpen={setOpen} 
            action={save} 
            body={
                <EticketModal 
                data={body} 
                required={required}
                setBodyData={setBodyData}
                setCheckActive={setCheckActive}
                />
            }/>
      </div>
      )

}