
import React, { useEffect, useState,useReducer, useContext,useCallback } from 'react'
import * as Helpers from '../../helpers/helpers'
import AlertContext from '../../context/contextAlert'
import { useNavigate , useLocation, useLoaderData } from "react-router-dom"
import { PlusIcon } from '@heroicons/react/24/solid'

import ServicesContext from '../../context/contextServices'
import lan from '../../translate'
import { country } from '../../helpers/country'
import ProfileContext from '../../context/contextProfile'
import moment from 'moment'
import Select from '../../components/select/select'
import FileDocument from '../../components/files/fileDocument'


const initialState = {
    name:"",
    no:"",
    alias:"",
    iata:"",
    icao:"",
    callsign:"",
    country:"",
    images:[],
    active:"",
    dateCreate:moment().format('DD/MM/YYYY'),
    hourCreate:moment().hours() + ":"+ moment().minutes()

}

const fieldRequired = {
    name:true,
    no:true,
    country:true,
}

const selectedValueInfo = {
    value:"",
    label:""
}
export default function AirlinesCreate() {

    const services =  useContext(ServicesContext)
    const MySwal = useContext(AlertContext)
    const {user}  =  useContext(ProfileContext)
    const location = useLocation();
    const navigate = useNavigate();
    const [body,setBody] = useState(initialState)
    const [idUser, setIdUser] = useState(0)
    const [isUpdate, setIsUpdate] = useState(false)
    const [required,setRequired] = useState(fieldRequired)
    const [options, setOptions] = useState(selectedValueInfo)
    let [fileList, dispatchFile] = useReducer((fileList, { type, value, position }) => {
      switch (type) {
          case "add":
          return [...fileList, value];
          case "show":
          return value
          case "update":
              fileList[position] =  value
          return [...fileList];
          case "remove":
          return fileList.filter((data,i)=> data.name !== value)
          case "load":
          return value
          default:
          return fileList;
      }
      }, []);

    const setBodyData = useCallback(event => {
        const { name, value } = event.target;
        setBody({ ...body, [name]: value });
        if(!value){
            setRequired({ ...required, [name]: lan.user_alert_fielderror })
        }else{
            setRequired({ ...required, [name]: "" })
        }
    });

    const setBodyDataSelect = useCallback(event => {
        const { name, value } = event.target;
        setBody({ ...body, [name]: value.label });
        if(!value){
            setRequired({ ...required, [name]: lan.user_alert_fielderror })
        }else{
            setRequired({ ...required, [name]: "" })
        }
    });

    const setCheckActive = useCallback(event => {
        const { name, checked } = event.target;
        setBody({ ...body, [name]: checked });
      
    });

    async function getAllValue() {
        const dataServices = await services.Countries.getAllFull()
        const list = dataServices.data
        let allItem = list.map((data,i)=>{
            return {value:data._id,label:data.name}
        })
        setOptions(allItem)
    }

    useEffect(()=>{
        loadData(location.state)
        console.log('location.state', location.state)
        getAllValue()
    },[])


    async function save() {
      MySwal.fire({
        title: lan.loading_save,
        allowOutsideClick: false,
      })
      MySwal.showLoading();
        if(Helpers.checkIsRequired(required)){

            MySwal.fire({
                confirmButtonColor: '#1EA0D5',
                icon: "warning",
                text:lan.user_alert_field_empty
              })
        }

        if(!Helpers.checkIsRequired(required)){

          if(fileList.length>0){
            console.log('fileList save', fileList)
            const resultFile = await services.File.uplodMultipleFile({files:fileList})
            if(resultFile.status !== 200 && resultFile.status !==201){
              return  MySwal.fire({
                confirmButtonColor: '#1EA0D5',
                icon: "error",
                title:lan.alert_errorcreate_generic,
                text: "Error: FileServer"
                }) 
            }
            body.images = resultFile.data
        }
 
            const result = await services.Airlines.save(body)
            if(result.status ===201){
                MySwal.fire({
                    confirmButtonColor: '#1EA0D5',
                    icon: "success",
                    text:lan.alert_create_generic
                  })
                return navigate('/airlines')
            }

            if(result.response && result.response.data.statusCode === 400){
                return  MySwal.fire({
                    confirmButtonColor: '#1EA0D5',
                    icon: "error",
                    title:lan.alert_errorcreate_generic,
                    text: "Error: "+result.response.data.message
                    })
            }

            MySwal.fire({
                confirmButtonColor: '#1EA0D5',
                icon: "error",
                text:lan.user_alert_error500
                })
                
        }
    }

    async function update() {
        delete body.create
        delete body.__v
        delete body._id
        delete body.dateCreate
        delete body.hourCreate
        MySwal.fire({
          title: lan.loading_save,
          allowOutsideClick: false,
        })
        MySwal.showLoading();
        if(Helpers.checkIsRequired(required)){

            MySwal.fire({
                confirmButtonColor: '#1EA0D5',
                icon: "warning",
                text:lan.user_alert_field_empty
              })
        }

        if(!Helpers.checkIsRequired(required) ){
          const diffFile =  fileList.filter(
            fileBody => !body.images.some(newFile => fileBody.name === newFile.name)
            )
            
            if(diffFile.length>0){
              const resultFile = await services.File.uplodMultipleFile({files:diffFile})
              if(resultFile.status !== 200 && resultFile.status !==201){
                return  MySwal.fire({
                  confirmButtonColor: '#1EA0D5',
                  icon: "error",
                  title:lan.alert_errorcreate_generic,
                  text: "Error: FileServer"
                  }) 
              }
              for (const key in resultFile.data) {
                body.images.push(resultFile.data[key])
              }
             
            }else{
                body.images = fileList
            }
            delete body.image
            const result = await services.Airlines.update(body,idUser)
            if(result.status ===200){
                MySwal.fire({
                    confirmButtonColor: '#1EA0D5',
                    icon: "success",
                    text:lan.alert_update_generic
                  })
                return 
            }

            if(result.response && result.response.data.statusCode === 400){
                return  MySwal.fire({
                    confirmButtonColor: '#1EA0D5',
                    icon: "error",
                    title:lan.alert_errorupdate_generic,
                    text: "Error: "+result.response.data.message
                    })
            }

            MySwal.fire({
                confirmButtonColor: '#1EA0D5',
                icon: "error",
                text:lan.user_alert_error500
                })
        }
        
        
    }


    function loadData(state) {
        if(state){
         setRequired({
             no:'',
             name:'',
         })
         dispatchFile({ type: "show", value: state.images, position:0})
         setBody(state)
         setIdUser(state._id)
         setIsUpdate(true)
        }
        
    }
  return (
    <div className="space-y-10 divide-y divide-gray-900/10">
    <div className="grid grid-cols-1 pt-10 gap-x-8 gap-y-8 md:grid-cols-3">
        
      <div className="px-4 sm:px-0">
        <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">{lan.airlines_titlecreate}</h2>
        <p className="mt-1 text-sm leading-6 text-gray-600">{lan.airlines_titlecreaatesub}</p>
      </div>

      <div className="bg-white shadow-sm dark:ring-1 dark:ring-inset dark:ring-white/10 dark:bg-slate-800 ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
        <div className="px-4 py-6 sm:p-8">
          <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
               {lan.airlines_field_no} <span className={required.no?"text-red-700":""}>*</span>
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name={"no"}
                  id={"no"}
                  value={body.no}
                  onChange={setBodyData}
                  autoComplete="given-name"
                  className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                />
              </div>
              <span className='text-xs text-red-700'>{required.no?required.no:""}</span>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
               {lan.airlines_field_name} <span className={required.name?"text-red-700":""}>*</span>
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name={"name"}
                  id={"name"}
                  value={body.name}
                  onChange={setBodyData}
                  autoComplete="given-name"
                  className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                />
              </div>
              <span className='text-xs text-red-700'>{required.name?required.name:""}</span>

              
            </div>


            <div className="sm:col-span-3">
              <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
               {lan.airlines_field_alias} <span className={required.alias?"text-red-700":""}>*</span>
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name={"alias"}
                  id={"alias"}
                  value={body.alias}
                  onChange={setBodyData}
                  autoComplete="given-name"
                  className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                />
              </div>
              <span className='text-xs text-red-700'>{required.alias?required.alias:""}</span>

              
            </div>


            <div className="sm:col-span-3">
              <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
               {lan.airlines_field_iata} <span className={required.iata?"text-red-700":""}>*</span>
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name={"iata"}
                  id={"iata"}
                  value={body.iata}
                  onChange={setBodyData}
                  autoComplete="given-name"
                  className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                />
              </div>
              <span className='text-xs text-red-700'>{required.iata?required.iata:""}</span>

              
            </div>


            <div className="sm:col-span-3">
              <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
               {lan.airlines_field_icao} <span className={required.icao?"text-red-700":""}>*</span>
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name={"icao"}
                  id={"icao"}
                  value={body.icao}
                  onChange={setBodyData}
                  autoComplete="given-name"
                  className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                />
              </div>
              <span className='text-xs text-red-700'>{required.icao?required.icao:""}</span>

              
            </div>


            <div className="sm:col-span-3">
              <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
               {lan.airlines_field_callsing} <span className={required.callsign?"text-red-700":""}>*</span>
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name={"callsign"}
                  id={"callsign"}
                  value={body.callsign}
                  onChange={setBodyData}
                  autoComplete="given-name"
                  className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
                />
              </div>
              <span className='text-xs text-red-700'>{required.callsign?required.callsign:""}</span>

              
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
               {lan.airlines_field_country} <span className={required.country?"text-red-700":""}>*</span>
              </label>
              <Select 
              options={options} 
              nameDefault={"country"}
              onMethod={setBodyDataSelect} 
              valueDefault={body.country}/>
              <span className='text-xs text-red-700'>{required.country?required.country:""}</span>

              
            </div>
            <div className="col-span-full">
            <div className="relative flex gap-x-3">
                  <div className="flex items-center h-6">
                    <input
                      id="active"
                      name="active"
                      type="checkbox"
                      checked={body.active}
                      onClick={setCheckActive}
                      className="w-4 h-4 border-gray-300 rounded text-blue-primary focus:blue-primary"
                    />
                  </div>
                  <div className="text-sm leading-6">
                    <label htmlFor="comments" className="font-medium text-gray-900 dark:text-white">
                      {lan.airlines_field_active}
                    </label>
                    <p className="text-gray-500">{lan.airlines_field_activesub}</p>
                  </div>
                </div>
            </div>

 
            
          </div>
        </div>
       
      </div>
    </div>

    <div  className="grid grid-cols-1 pt-10 gap-x-8 gap-y-8 md:grid-cols-3">
        
        <div className="px-4 sm:px-0">
          <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">{lan.airlines_images_title}</h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">{lan.airlines_images_titlesub}</p>
        </div>
  
        <div className="bg-white shadow-sm dark:ring-1 dark:ring-inset dark:ring-white/10 dark:bg-slate-800 ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
          <div className="px-4 py-6 sm:p-8">
            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

        
            {fileList.length>0&&fileList.map((data,i)=>
              <div className="flex flex-wrap sm:col-span-3">
                <FileDocument accept=".jpg,.jpeg,.png" ruteFile={Helpers.getRootFolder('airlines/')} category={"images"}  position={i} fileList={fileList} setMethod={dispatchFile}/>
              </div>
            )}
             <div className="sm:col-span-3">
              <div onClick={()=>dispatchFile({ type: "add", value: "", position:0})} className='flex flex-wrap w-full gap-12 cursor-pointer'>
                <div className="flex justify-center px-6 py-8 mt-2 border border-indigo-600 border-dashed rounded-lg">
                  <div className="text-center">
                    <PlusIcon  className="h-8 mx-auto text-gray-300 w-15" aria-hidden="true"/>
                    <div className="flex justify-center mt-4 text-sm leading-6 text-center text-gray-600">
                     
                    </div>
                    <p className="text-xs leading-5 text-gray-600"> Agregar  Archivos </p>

                  </div>
              </div>
              </div>
                </div>
              
            </div>
          </div>
         
        </div>
    </div>

   

    <div className="flex items-center justify-end px-4 py-4 border-t col-span-full gap-x-6 border-gray-900/10 dark:border-white/10 sm:px-8">
          <button type="button" className="text-sm font-semibold leading-6 text-blue-primary" onClick={()=>navigate(-1)}>
            {lan.user_button_cancel}
          </button>
          <button
            type="submit"
            onClick={()=> isUpdate? update() : save()}
            className="px-3 py-2 text-sm font-semibold text-white rounded-md shadow-sm bg-blue-primary hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {lan.user_button_save}
          </button>
        </div>
  </div>
  )
}
