import lan from '../../translate';
import api from '../api/create';
import {Countries} from '../index'
function getAgencyInfo(tenantId) {
    return api.get(`/agencies/${tenantId}`)
}

function updateAgency(tenantId,body) {
    return api.patch(`/agencies/tenant/${tenantId}`,body)
}


function customFields() {
    return  [
        {
            name:"companyName",
            value:"",
            required:"",
            type:"text",
            label:lan.agency_field_name
        },
        {
            name:"social_name",
            value:"",
            required:"",
            type:"text",
            label:lan.agency_field_social_name
        },
        {
            name:"rut",
            value:"",
            required:"",
            type:"text",
            label:lan.agency_field_rut
        },
        {
            name:"iata",
            value:"",
            required:"",
            type:"text",
            label:lan.agency_field_iata
        },
        {
            name:"telephone",
            value:"",
            required:"",
            type:"number",
            label:lan.agency_field_telephone
        },
        {
            name:"cellphone",
            value:"",
            required:"",
            type:"number",
            label:lan.agency_field_cellphone
        },
        {
            name:"email",
            value:"",
            required:"",
            type:"text",
            label:lan.agency_field_email
        },
        
        {
            name:"type_company",
            value:"",
            required:"",
            type:"selectLocal",
            label:lan.agency_field_type_company,
            options:[
                  {
                    "value": "company",
                    "label": "Empresa"
                  },
                  {
                    "value": "fisica",
                    "label": "Persona Fisica"
                  }
            ]

        },
        {
            name:"document_type",
            value:"",
            required:"",
            type:"selectLocal",
            label:lan.agency_field_document_type,
            options:[
                  {
                    "value": "RNC",
                    "label": "RNC"
                  },
                  {
                    "value": "CEDULA",
                    "label": "CEDULA"
                  }
            ]

        },
        {
            name:"document",
            value:"",
            required:"",
            type:"number",
            label:lan.agency_field_document
        },
        {
            name:"city",
            value:"",
            required:"",
            type:"text",
            label:lan.agency_field_city
        },
        {
            name:"province",
            value:"",
            required:"",
            type:"text",
            label:lan.agency_field_province
        },
        {
            name:"country",
            value:"",
            required:"",
            col:6,
            type:"combobox",
            getValue:"name",
            labels:["name","code"],
            services:Countries.findAllLike,
            label:lan.agency_field_country
        },

        {
            name:"zip_code",
            value:"",
            required:"",
            col:3,
            type:"number",
            label:lan.agency_field_zip_code
        },
        {
            name:"language",
            value:"",
            required:"",
            col:3,
            type:"selectLocal",
            label:lan.agency_field_lang,
            options:[
                  {
                    "value": "es",
                    "label": "ESPAÑOL"
                  },
                  {
                    "value": "en",
                    "label": "ENGLISH"
                  },
                  {
                    "value": "pr",
                    "label": "PORTUGES"
                  },
            ]

        },
        
        {
            name:"address",
            value:"",
            required:"",
            col:12,
            type:"textarea",
            label:lan.agency_field_address
        },

        {
            name:"description",
            value:"",
            required:"",
            col:12,
            type:"textarea",
            label:lan.agency_field_description
        },
        
       
        {
            name:"usd_price_buy",
            value:"",
            required:"",
            type:"number",
            label:lan.agency_field_usd_buy
        },
        {
            name:"usd_price_sell",
            value:"",
            required:"",
            type:"number",
            label:lan.agency_field_usd_sell
        },
        {
            name:"eur_price_buy",
            value:"",
            required:"",
            type:"number",
            label:lan.agency_field_eur_buy
        },
        {
            name:"eur_price_sell",
            value:"",
            required:"",
            type:"number",
            label:lan.agency_field_eur_sell
        },
        {
            name:"color_primary",
            value:"",
            required:"",
            col:6,
            type:"color",
            label:lan.agency_field_color_primary
        },
        {
            name:"color_second",
            value:"",
            required:"",
            col:6,
            type:"color",
            label:lan.agency_field_color_second
        },
    ]
    
}


export {
    getAgencyInfo,
    customFields,
    updateAgency
}
