
import {
    FolderIcon,
    HomeIcon,
    UsersIcon,
    PaperAirplaneIcon,
    ListBulletIcon,
    BriefcaseIcon,
    UserCircleIcon,
    GlobeAmericasIcon,
    BuildingLibraryIcon,
    ArrowUturnLeftIcon,
    LanguageIcon,
    ArrowDownLeftIcon,
    ArrowPathRoundedSquareIcon
  } from '@heroicons/react/24/outline'

import Dashboard from './views/dashboard/dashboard';
import Pasangers from './views/passangers/passangers';
import Users from './views/users/users';
import UserCreate from './views/users/userCreate';
import lan from './translate';
import PassengerCreate from './views/passangers/passengerCreate';
import Flights from './views/flights/flights';
import { FlightCreate } from './views/flights/flightCreate';
import Countries from './views/countries/countries';
import CountriesCreate from './views/countries/countriesCreate';
import Airlines from './views/airlines/airlines';
import AirlinesCreate from './views/airlines/airlinesCreate';
import Airports from './views/airports/airports';
import AirportCreate from './views/airports/airportsCreate';
import WorkFlight from './views/work-flight/work-flight';
import Supplier from './views/supplier/supplier';
import SupplierCreate from './views/supplier/supplierCreate';
import WorkFlightCreate from './views/work-flight/work-flightCreate';
import GenericList from './views/genericList/genericList';
import GenericListCreate from './views/genericList/genericListNew';
import Files from './views/files/files';
import FilesCreate from './views/files/fileCreate';
import DocumentFlight from './views/work-flight/documents-flight'
import EticketPhoto from './views/eticket/eticketPhoto';
import { redirect } from 'react-router-dom';
import Login from './views/login/login';
import RutesFlight from './views/rutes-flight/ruteFlight';
import RuteFlightCreate from './views/rutes-flight/rutes-flightNew';
import Tour from './views/tours/tours';
import ToursCreate from './views/tours/toursCreate';
import TourPackage from './views/tours/tour-package';
import ToursPackageCreate from './views/tours/tour-packageNew';
import TourBookings from './views/tours/tour-booking';
import ToursBookingsCreate from './views/tours/tour-bookingsNew';
import Translate from './views/translate/translate';
import Settings from './views/settings/settings';


const navigation = [
    { 
        name: lan.navigation_dasboard,
        children:[
            { name: "Dashboard", href: '/',initial: 'Us', icon: HomeIcon, current: false, element: <Dashboard/>, hidden:false },
        ], 
        href: '/dashboard', 
        icon: HomeIcon, 
        current: true, 
        element: <></>, 
        hidden:false },
    { 
        name: lan.workflight_navigation_workflight, 
        href: '/workflight', 
        children:[
            { name: lan.workflight_navigation_psg, href: '/workflight/passengers',initial: 'Us', icon: UsersIcon, current: false, element: <WorkFlight/>, hidden:false },
            { name: lan.workflight_navigation_document, href: '/workflight/documents',initial: 'Us', icon: UsersIcon, current: false, element: <DocumentFlight/>, hidden:false },
            { name: lan.eticket_photo, href: '/workflight/eticketphoto',initial: 'Us', icon: UsersIcon, current: false, element: <EticketPhoto/>, hidden:false },

        ], 
        icon: PaperAirplaneIcon, 
        current: true, 
        element: null, 
        hidden:false 
    },

    { 
        name: lan.tour_worktours, 
        href: '/worktours', 
        children:[
            { name: lan.tour_title, href: '/worktours/tours',initial: 'Us', icon: UsersIcon, current: false, element: <Tour/>, hidden:false },
            { name: lan.tour_packge_title, href: '/worktours/package',initial: 'Us', icon: UsersIcon, current: false, element: <TourPackage/>, hidden:false },
            { name: lan.tour_reservation_title, href: '/worktours/bookings',initial: 'Us', icon: UsersIcon, current: false, element: <TourBookings/>, hidden:false },

        ], 
        icon: PaperAirplaneIcon, 
        current: true, 
        element: null, 
        hidden:false 
    },

]
const subNavigations = [
    { name: lan.file_path,children:[], href: '/files',initial: 'Fi', icon: FolderIcon, current: false, element: <Files/>, hidden:false },
    { name: lan.supplier_navigation,children:[], href: '/supplier',initial: 'Su', icon: BriefcaseIcon, current: false, element: <Supplier/>, hidden:false },
    { name: lan.generic_title,children:[], href: '/generic-list',initial: 'Ge', icon: ListBulletIcon, current: false, element: <GenericList/>, hidden:false },
    { name: lan.navigation_users,children:[], href: '/users',initial: 'Us', icon: UserCircleIcon, current: false, element: <Users/>, hidden:false },
    { name: lan.navigation_passangers,children:[], href: '/passangers',initial: 'Pa', icon: UsersIcon, current: false, element: <Pasangers/>, hidden:false },
    { name: lan.flights_rute_name,children:[], href: '/flights',initial: 'Fl', icon: PaperAirplaneIcon, current: false, element: <Flights/>, hidden:false },
    { name: lan.countries_rute,children:[], href: '/countries',initial: 'Co', icon: GlobeAmericasIcon, current: false, element: <Countries/>, hidden:false },
    { name: lan.airlines_title,children:[], href: '/airlines',initial: 'Ai', icon: ArrowDownLeftIcon, current: false, element: <Airlines/>, hidden:false },
    { name: lan.airport_title,children:[], href: '/airports',initial: 'Ap', icon: BuildingLibraryIcon, current: false, element: <Airports/>, hidden:false },
    { name: lan.rute_fligt_title,children:[], href: '/rute-flight',initial: 'Rf', icon: ArrowPathRoundedSquareIcon, current: false, element: <RutesFlight/>, hidden:false },
    { name: lan.translate_title,children:[], href: '/translate',initial: 'Tr', icon: LanguageIcon, current: false, element: <Translate/>, hidden:false },


]
const userNavigation = [
    { name: lan.navigation_your_profile,children:[], href: '#', element: <></>, hidden:false },
    
]

const othersNavigation = [
    { name: lan.file_path,children:[], href: '/files/new', element: <FilesCreate/>, hidden:false },
    { name: lan.navigation_new_profile,children:[], href: '/users/new', element: <UserCreate/>, hidden:false },
    { name: lan.generic_title,children:[], href: '/generic-list/new', element: <GenericListCreate/>, hidden:false },
    { name: lan.passengers_new_profile,children:[], href: '/passengers/new', element: <PassengerCreate/>, hidden:false },
    { name: lan.flight_newflight,children:[], href: '/flights/new', element: <FlightCreate/>, hidden:false },
    { name: lan.countries_btn_table_create,children:[], href: '/countries/new', element: <CountriesCreate/>, hidden:false },
    { name: lan.airlines_btn_create,children:[], href: '/airlines/new', element: <AirlinesCreate/>, hidden:false },
    { name: lan.airport_btn_create,children:[], href: '/airports/new', element: <AirportCreate/>, hidden:false },
    { name: lan.workflight_btn_create_new,children:[], href: '/workflight/new', element: <WorkFlightCreate/>, hidden:false },
    { name: lan.supplier_navigation_new,children:[], href: '/supplier/new', element: <SupplierCreate/>, hidden:false },
    { name: lan.rute_fligt_title,children:[], href: '/rute-flight/new', element: <RuteFlightCreate/>, hidden:false },
    { name: lan.tour_title,children:[], href: '/worktours/tours/new', element: <ToursCreate/>, hidden:false },
    { name: lan.tour_packge_title,children:[], href: '/worktours/package/new', element: <ToursPackageCreate/>, hidden:false },
    { name: lan.tour_reservation_title,children:[], href: '/worktours/bookings/new', element: <ToursBookingsCreate/>, hidden:false },
    { name: lan.tour_reservation_title,children:[], href: '/settings/', element: <Settings/>, hidden:false },

    
]



export  {
    navigation,
    subNavigations,
    userNavigation,
    othersNavigation
}
  