
import { useContext, useEffect, useState } from 'react'
import { Doughnut, Bar,Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title
} from 'chart.js';

// Register necessary components

import ProfileContext from '../../context/contextProfile'
import CardChart from '../../components/cards/cardChart';
import {
  FolderIcon,
  HomeIcon,
  UsersIcon,
  PaperAirplaneIcon,
  ListBulletIcon,
  BriefcaseIcon,
  UserCircleIcon,
  GlobeAmericasIcon,
  BuildingLibraryIcon,
  ArrowUturnLeftIcon,
  LanguageIcon,
  ArrowDownLeftIcon,
  ArrowPathRoundedSquareIcon
} from '@heroicons/react/24/outline'
import Calendar from '../../components/calendar/calendar';


export default  function Dashboard() {
  ChartJS.register(CategoryScale, ArcElement,LinearScale,PointElement,LineElement, BarElement, Title, Tooltip, Legend);


  const {setUser, user, getUserInfo} =  useContext(ProfileContext)
  const data = {
    labels: ['Vuelos', 'Propiedades', 'Tours', 'Seguros', 'Rent Car'],
    datasets: [
      {
        label: 'Colors Distribution',
        data: [12, 19, 3, 5, 2], // Sample data
        backgroundColor: [
          '#EC4899',
          '#F67316',
          '#39A0FF',
          '#EF4444',
          '#9f00bf',
        ],
        borderColor: [
          '#FFFFFF',
          '#FFFFFF',
          '#FFFFFF',
          '#FFFFFF',
          '#FFFFFF',
        ],
        borderWidth: 2,
      },
    ],
  };

  const sell = {
    labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo'],
    datasets: [
      {
        label: 'Venta por mes',
        data: [12, 19, 3, 5, 2], // Sample data
        backgroundColor: [
          '#EC4899',
          '#F67316',
          '#39A0FF',
          '#EF4444',
          '#9f00bf',
        ],
        borderColor: [
          '#FFFFFF',
          '#FFFFFF',
          '#FFFFFF',
          '#FFFFFF',
          '#FFFFFF',
        ],
        borderWidth: 2,
      },
    ],
  };

  const gasto = {
    labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo'],
    datasets: [
      {
        label: 'Gastos por mes',
        data: [12, 19, 3, 5, 2], // Sample data
        backgroundColor: [
          'rgba(245, 39, 145, 0.48)',
          'rgba(240, 92, 0, 0.48)',
          'rgba(240, 0, 0, 0.54)',
          'rgba(0, 137, 240, 0.54)',
          'rgba(227, 0, 240, 0.54)',
        ],
        borderColor: [
          '#EC4899',
          '#EF4444',
          '#F67316',
          '#39A5E9',
          '#4C51BF',
        ],
        borderWidth: 1,
      },
    ],
  };

  const earning = {
    labels: ['Ingresos', 'Gastos',],
    datasets: [
      {
        label: 'Flujo',
        data: [12, 19], // Sample data
        backgroundColor: [
          
          '#39A5E9',
          '#EF4444',
        ],
        borderColor: [
          
          '#FFFFFF', 
          '#FFFFFF',
        ],
        borderWidth: 2,
      },
    ],
  };






  
 
  useEffect(()=>{
  },[])
  return (
    // <Doughnut data={data} />
    <div id="main" className='main'>
      <div id='cards' className='flex flex-row flex-wrap justify-between gap-3 p-1 md:flex-nowrap '>
        <CardChart update='actualizado hace 26 minutos' name='Empleados registrados' data={23} color='#EC4899' Icon={UserCircleIcon}/>  
        <CardChart name='Reserva de alojamiento' data={17} color='#EF4444' Icon={HomeIcon}/>  
        <CardChart name="Cantidad de clientes" data={124} color='#F67316' Icon={UsersIcon}/>  
        <CardChart name='Reserva de tours' data={40} color='#39A5E9' Icon={BriefcaseIcon}/>    
      </div>
      <br/>
      <div id='charts' className='flex flex-row flex-wrap justify-between w-full gap-5 lg:flex-nowrap '>
          <div className='w-full bg-white rounded-lg shadow-lg dark:bg-slate-800'>
          <Bar data={sell}  />
          </div>
          <div className='w-1/2 p-1 bg-white rounded-lg shadow-lg dark:bg-slate-800'>
            <Doughnut data={earning} />
          </div>
          <div className='w-full bg-white rounded-lg shadow-lg dark:bg-slate-800'>
          <Line data={gasto}  />
          </div>
      </div>
      <br/>
      <div id='chart3' className='flex flex-row flex-wrap justify-between w-full gap-5 lg:flex-nowrap '>
          <div className='w-1/2 bg-white rounded-lg shadow-lg dark:bg-slate-800'>
            <Doughnut data={data} />
          </div>
          <div className='w-full p-2 bg-white rounded-lg shadow-lg dark:bg-slate-800'>
            <Calendar/>
          </div>
     
      </div>
    <br/>
      
      
    </div>

  )
}
