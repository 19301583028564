import React, { useEffect } from 'react'
import SelectApi from '../select/selectApi'
import moment from 'moment'
import { Switch } from '@headlessui/react'
import Combobox from '../../components/combobox/combobox'

export default function DinamicFields({type,onBlur=null,name,value,required,label,setBodyData,apiType="",options=false,col=3,labels=[],services={},getValue=null}){
    return (
    <div className={"sm:col-span-"+col}>
    <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white ">
     {label} <span className={required?"text-red-700":""}>*</span>
    </label>
    {type === 'text'&&(
      <div className="mt-2">
        <input
          type={type}
          name={name}
          id={name}
          value={value}
          onBlur={(e)=>onBlur?onBlur(e):{}}
          onChange={setBodyData}
          autoComplete="given-name"
          className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
        />
      </div>
    )}
    {type === 'color'&&(
      <div className="mt-2">
        <input
          type={type}
          name={name}
          id={name}
          value={value}
          onBlur={(e)=>onBlur?onBlur(e):{}}
          onChange={setBodyData}
          autoComplete="given-name"
          className="block w-full text-gray-900 border-0 rounded-md shadow-sm dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
        />
      </div>
    )}
    {type === 'time'&&(
      <div className="mt-2">
        <input
          type={type}
          name={name}
          id={name}
          value={value}
          onChange={setBodyData}
          autoComplete="given-name"
          className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
        />
      </div>
    )}
    {type === 'number'&&(
      <div className="mt-2">
        <input
          type={type}
          name={name}
          id={name}
          value={value}
          onBlur={(e)=>onBlur?onBlur(e):{}}
          onChange={setBodyData}
          autoComplete="given-name"
          className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
        />
      </div>
    )}
    {type === 'checkbox'&&(
      <div className="mt-2">
        <input
          type={type}
          name={name}
          id={name}
          checked={value}
          value={value}
          onChange={setBodyData}
          autoComplete="given-name"
          className="w-4 h-4 border-gray-300 rounded text-blue-primary focus:blue-primary"
        />
      </div>
    )}

    {type === 'file'&&(
      <div className="mt-2">
        <input
          type={type}
          name={name}
          id={name}
          value={value}
          onChange={setBodyData}
          autoComplete="given-name"
          className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
        />
      </div>
    )}

    {type === 'date'&&(
      <div className="mt-2">
        <input
          type={type}
          name={name}
          id={name}
          value={value}
          onChange={setBodyData}
          autoComplete="given-name"
          className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
        />
      </div>
    )}

    {type === 'textarea'&&(
      <div className="block w-full mt-2">
        <textarea
          type={type}
          name={name}
          id={name}
          value={value}
          onChange={setBodyData}
          autoComplete="given-name"
          className="block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6"
        />
      </div>
    )}

    {type === 'select'&&(
      <div className="block w-full mt-2">
        <SelectApi
          apiType={apiType}
          name={name}
          id={name}
          value={value}
          onChange={setBodyData}
          autoComplete="given-name"
        />
      </div>
    )}

    {type === 'combobox'&&(
      <div className="block w-full mt-2">
        <Combobox
          nameDefault={name}
          labels={labels}
          getValue={getValue}
          services={services} 
          value={value}
          method={setBodyData}/>
      </div>
    )}

    {type === 'selectLocal'&&(
      <div className="block w-full mt-2">
        <select 
        type={type}
        name={name}
        id={name}
        value={value}
        onChange={setBodyData}
        className='block w-full rounded-md border-0 py-1.5 dark:ring-1 dark:ring-inset dark:text-white dark:ring-white/10 dark:bg-white/5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:blue-primary sm:text-sm sm:leading-6'>
        <option className='dark:bg-slate-900' value={""}>Seleccione</option>
          {options&&options.map((data,i)=>
            <option className='dark:bg-slate-900' value={data.value}>{data.label}</option>
          )}
        </select>
      </div>
    )}


    {type === 'switch'&&(
          <div className="block w-full mt-2">
            <Switch
            checked={value}
            onChange={(e)=>setBodyData({target:{name:name,checked:e,type:type}})}
            className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-indigo-600"
          >
            <span className="sr-only">Use setting</span>
            <span
              aria-hidden="true"
              className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
            />
          </Switch>
          </div>
        )}
    
    <span className='text-xs text-red-700'>{required?required:""}</span>
    
  </div>
  )
}
